import { Order, OrderStatus } from "interfaces/commerce/order/order";

export const XRL_ORDER_ID_INOVIO_QUERY_PARAM = "xtl_order_id";


export const findOrderByStatesIn = (orders: Order[], statuses: OrderStatus[]) =>
  orders.find(o => statuses.includes(o.status));

export const hasActive = (orders: Order[]) => {
  return findOrderByStatesIn(orders, ["NEW"]) !== undefined;
};