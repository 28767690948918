import { CONTEST_STATE } from "utils/constants.ts";
import { ContestStatusBanner } from "./index.ts";


interface Props {
  status: string;
}

const ContestStatusDisplay = ({status}: Props) => {
  const contestLabelInfo = getStatusInfo(status);

  return (
    <ContestStatusBanner text={contestLabelInfo.text} color={contestLabelInfo.color}/>
  )

}

export default ContestStatusDisplay;

const getStatusInfo = (status: string) => {
  switch (status) {
    case CONTEST_STATE.PENDING:
      return {
        text: "Pending",
        color: "var(--secondary-color)"
      }
    case CONTEST_STATE.OPEN:
      return {
        text: "Open",
        color: "var(--sparket-dark-green",
      };
    case CONTEST_STATE.LIVE:
      return {
        text: "In Progress",
        color: "var(--secondary-color)",
      }
    case CONTEST_STATE.SETTLED:
      return {
        text: "Settled",
        color: "var(--secondary-color)",
      };
    default:
      return {
        text: "Unknown",
        color: "var(--secondary-color)",
      };
  }
};