import React from "react";
import { Product } from "interfaces/commerce/product";
import { BattlePass } from "interfaces/battle-pass";
import { Check } from "react-bootstrap-icons";
import dateFormatter from "utils/formatter-utils/date-formatter";
import { secondaryColor } from "utils/constants";
import { Order } from "interfaces/commerce/order/order";
import NavigateToInovioHostedPaymentPageButton
  from "components/payment-provider/inovio/navigate-to-inovio-hosted-payment-page-button.tsx";
import { findOrderByStatesIn } from "payment-provider/inovio/inovio";
import { Badge } from "react-bootstrap";

interface ProductItemProps {
  battlePass: BattlePass;
  product: Product;
  productOrders: Order[];
  isSelected: boolean;
  onClick: () => void;
}

const BattlePassProductItem: React.FC<ProductItemProps> = ({
                                                             battlePass,
                                                             product,
                                                             isSelected,
                                                             onClick,
                                                             productOrders
                                                           }) => {
  const activeOrder = findOrderByStatesIn(productOrders, ["NEW"]);

  return (
    <div
      key={battlePass.id}
      onClick={(activeOrder || product.purchased) ? undefined : onClick}
      className="p-2 mb-4"
      style={{
        outline: isSelected ? `3px solid ${secondaryColor}` : "1px solid gray",
        cursor: (activeOrder || product.purchased) ? "not-allowed" : "pointer",
        color: "white",
      }}
    >
      <div className={"row"}>
        <div className="col-10">
          <div><strong>Product:</strong> <span>{product.name}</span></div>
          <div><strong>Description:</strong> <span>{battlePass.description}</span></div>
          <div><strong>Start:</strong> <span>{dateFormatter(battlePass.begin)}</span></div>
          <div><strong>Finish:</strong> <span>{dateFormatter(battlePass.finish)}</span></div>
          <div><strong>Price:</strong> <span>${product.price}</span></div>
          {product.purchased && <Badge bg="success">PURCHASED</Badge>}
        </div>
        {!product.purchased &&
          <div className="col-2 d-flex align-items-start justify-content-end">
            {
              activeOrder && ["PAYMENT_REQUEST_SENT", "PENDING", "RUNNING", "PENDING_CONFIRMATION"].includes(activeOrder.payment_status) &&
              <Badge bg={"info"} pill={true} style={{ color: "black" }}>PROCESSING PAYMENT</Badge>
            }

            {isSelected && <Check size={40} color="green" />}
          </div>
        }
      </div>

      {activeOrder &&
        activeOrder.status === "NEW" &&
        activeOrder.payment_status === "PAYMENT_REQUIRED" &&
        activeOrder.payment_page_url &&
        <NavigateToInovioHostedPaymentPageButton title={"Complete Payment"}
                                                 provideInovioHostedPaymentPageURL={() => Promise.resolve(activeOrder!!.payment_page_url)} />}
    </div>
  );
};

export default BattlePassProductItem;
