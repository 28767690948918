import { Contest } from "interfaces/leaderboard/contest.ts";
import { ContestItemHome } from "components/contests/contest-display-items";
import { useAppSelector } from "state/hooks.ts";
import { getContestsState } from "state/slices/contests-slice.ts";
import styles from "components/my-bets/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { CONTEST_STATE } from "utils/constants.ts";

const OpenContests = () => {
  const contests = useAppSelector(getContestsState).contests;

  const openContests = contests.filter(c => c.state === CONTEST_STATE.OPEN);

  if (openContests.length === 0) {
    return (
      <div className={styles.noBetsTextContainer}>
        <BlockyHeavyText>No open contests</BlockyHeavyText>
      </div>
    );
  }

  return (
    <>
      {openContests.map((contest: Contest) => {
        return (
          <ContestItemHome
            key={contest.id}
            contest={contest}
          />
        );
      })}
    </>
  );
};

export default OpenContests;