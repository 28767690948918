import { JoinedContest } from "interfaces/leaderboard/contest.ts";
import { getContestById } from "state/slices/contests-slice.ts";
import { useAppSelector } from "state/hooks.ts";
import styles from "./styles.module.css"
import { useNavigate } from "react-router-dom";
import TimeDisplay from "./shared/time-display.tsx";
import {
  ContestNameDisplay,
  MaxEntriesDisplay,
  EntryFeeDisplay,
  ContestStatusDisplay
} from "./shared/contest-displays";

interface Props {
  joinedContest: JoinedContest;
}

const JoinedContestItem = ({joinedContest}: Props) => {

  const contest = useAppSelector((state) => getContestById(state, joinedContest.contest_id));
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/contest/${contest?.id}`, {
      state: {
        contestEntryId: joinedContest.entries[0].id,
      }
    });
  }

  if (!contest) {
    return <></>;
  }

  const nameFlex = .6;
  const feeFlex = (1 - nameFlex) / 2

  return (
    <div className={styles.wrapper} onClick={handleClick}>
      <div className={styles.contestContainer}>
        <div className={styles.infoRow}>
          <div style={ {flex: nameFlex} }>
            <ContestNameDisplay name={ contest.name } />
            <TimeDisplay endAt={contest.end_at}/>
          </div>

          <div style={ {flex: feeFlex} } >
            <EntryFeeDisplay entryFee={ contest.entry_fee }/>
          </div>

          <div style={ {flex: feeFlex} } >
            <MaxEntriesDisplay totalEntries={joinedContest.num_entries} />
          </div>
        </div>
        <ContestStatusDisplay status={contest.state} />
      </div>
    </div>
  )

}

export default JoinedContestItem;