import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "state/store";

export const APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY = "appConfigOverrides";
const APP_CONFIG_QUERY_PARAM_PREFIX = "appConfig";

export interface AppConfig {
  key: boolean;
}

export const defaultAppConfig: AppConfig = {
  key: false
};

export const getAppConfigOverridesFromLocalStorage = (): Partial<AppConfig> => {
  const storedOverrides = localStorage.getItem(APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY);
  return storedOverrides ? JSON.parse(storedOverrides) : {};
};

export const checkIfAppConfigResetIsRequired = () => {
  const params = new URLSearchParams(window.location.search);
  return params.has(`${APP_CONFIG_QUERY_PARAM_PREFIX}Reset`);
};

export const getAppConfigOverridesFromQueryParams = (): Partial<AppConfig> => {
  const params = new URLSearchParams(window.location.search);
  const overrides: Partial<AppConfig> = {};

  params.forEach((paramValue, paramName) => {
    if (paramName.startsWith(APP_CONFIG_QUERY_PARAM_PREFIX)) {
      const configKey = paramName.replace(APP_CONFIG_QUERY_PARAM_PREFIX, "") as keyof AppConfig;

      if (configKey in defaultAppConfig) {
        if (paramValue === "true") {
          overrides[configKey] = true;
        } else if (paramValue === "false") {
          overrides[configKey] = false;
        } else if (!isNaN(Number(paramValue))) {
          overrides[configKey] = Number(paramValue) as any;
        } else {
          overrides[configKey] = paramValue as any;
        }
      }
    }
  });

  return overrides;
};

const slice = createSlice({
  name: "appConfig",
  initialState: ({ ...defaultAppConfig, ...getAppConfigOverridesFromLocalStorage() }),
  reducers: {
    setAppConfig(state, action: PayloadAction<Partial<AppConfig>>) {
      return { ...state, ...action.payload };
    }
  },
});


export const getAppConfig = (state: RootState): AppConfig => state.appConfig;

export const { setAppConfig } = slice.actions;

export default slice.reducer;
