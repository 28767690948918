/**
 * @fileoverview SelectionHeader component displays the header and allows user to select the category of rewards to display
 */
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Nav, Tab } from "react-bootstrap";
import { RewardEnum } from "utils/constants";
import React, { useState } from "react";
import { setSelectedCategory } from "state/slices/avatar-modal-slice";
import { useDispatch } from "react-redux";
import BlockyLightText from "components/custom-texts/blocky-light-text.tsx";

const SelectionHeader : React.FC = () => {
  const [selection, setSelection] = useState<RewardEnum>(RewardEnum.AVATAR);
  const dispatch = useDispatch();

  const handleCategoryChange = (selectedCategory: RewardEnum) => {

    setSelection(selectedCategory);
    dispatch(setSelectedCategory(selectedCategory));
  }

  return (
    <div className={styles.text}>
      <BlockyBoldText>
        Selection Library
      </BlockyBoldText>

      <Tab.Container
        id="section-header-tabs"
        activeKey={selection}
        onSelect={(key) => handleCategoryChange(key as RewardEnum)}
      >
        <Nav
          variant="pills"
          style={{width: "100%", display: "flex", justifyContent: "center", marginTop: "1rem"}}
        >
          {Object.values(RewardEnum).map((value) => (
            <Nav.Item key={value} style={{ width: "50%" }}>
              <Nav.Link
                eventKey={value}
                className={styles.tabLink}
                style={{
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                <BlockyLightText>{value}s</BlockyLightText>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </Tab.Container>

    </div>
  )

}

export default SelectionHeader;