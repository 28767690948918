import React, { CSSProperties } from "react";
import { Order } from "interfaces/commerce/order/order";
import { Badge, Card, Col, Container, Row } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { getGroupStyles } from "state/slices/group-slice";
import NavigateToInovioHostedPaymentPageButton
  from "components/payment-provider/inovio/navigate-to-inovio-hosted-payment-page-button.tsx";

interface OrderItemProps {
  order: Order;
}

const OrderItem: React.FC<OrderItemProps> = ({ order }) => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const badgeStyle = {
    width: "100%",
    maxWidth: "150px",
    color: "black",
    textAlign: "center",
    padding: "0.2em",
    // marginBottom: "0.2em",
    whiteSpace: "normal",
    wordWrap: "break-word",
  } as CSSProperties;

  const getStatusBadge = (order: Order) => {
    const { status, payment_status, payment_page_url } = order;

    if (status === "COMPLETED") {
      return <Badge bg="success" style={badgeStyle}>Completed</Badge>;
    }

    if (status === "EXPIRED") {
      return <Badge bg="light" style={badgeStyle}>Expired</Badge>;
    }

    if (status === "NEW") {
      if (payment_page_url) {
        if (payment_status === "PAYMENT_REQUIRED") {
          return <Badge bg="info" style={badgeStyle}>Payment Required</Badge>;
        }

        if (payment_status === "PENDING_CONFIRMATION") {
          return <Badge bg="info" style={badgeStyle}>Processing</Badge>;
        }
      }
    }

    if (status === "PAYMENT_FAILED") {
      return <Badge bg="danger" style={badgeStyle}>Payment Failed</Badge>;
    }
  };


  const statusBadge = getStatusBadge(order);

  return (
    <Card
      style={{
        backgroundColor: "#2c2c2c",
        color: "#f5f5f5",
        width: "90%",
        borderRadius: "unset",
        border: "2px solid " + primaryColor,
        padding: "16px",
        marginBottom: "16px",
        position: "relative"
      }}
    >
      <Card.Body>
        <Card.Title style={{ borderBottom: "1px solid " + primaryColor }}>
          <Container>
            <Row className={"mb-2 position-relative"}>

              <Col className={"col-7 text-center d-flex align-items-center justify-content-center"}>
                {order.product ? order.product.name : "Deposit Funds"}
              </Col>

              {statusBadge && (
                <Col className={"col-5 d-flex justify-content-end align-items-center"} style={{ right: 10 }}>
                  {statusBadge}
                </Col>
              )}
            </Row>
          </Container>
        </Card.Title>
        <Card.Subtitle
          className="mb-2 text-muted fw-bold"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textAlign: "center",
            textOverflow: "ellipsis"
          }}
        >
          {order.id}
        </Card.Subtitle>
        <Card.Body>
          <Container>
            <Row>
              {new Date(order.created_at).toLocaleString()}
            </Row>
            <Row>
              ${order.price.toFixed(2)}
            </Row>
            <Row>
              {order.card_last4 && <p className="p-0">Paid with : ****************{order.card_last4}</p>}
            </Row>
          </Container>
        </Card.Body>

        {order.status === "NEW" && order.payment_status === "PAYMENT_REQUIRED" && order.payment_page_url &&
          <NavigateToInovioHostedPaymentPageButton title={"Complete Payment"}
                                                   provideInovioHostedPaymentPageURL={() => Promise.resolve(order.payment_page_url)} />}
      </Card.Body>
    </Card>
  );
};

export default OrderItem;
