import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { fetchOrders, getOrdersState } from "state/slices/user-orders-slice.ts";
import { putRequest } from "utils/httpClient.ts";
import { findOrderByStatesIn } from "payment-provider/inovio/inovio.ts";
import { useAuth } from "hooks/auth.tsx";
import { getUserState } from "state/slices/user-slice.ts";
import { toast } from "react-toastify";
import ConfettiExplosion from "react-confetti-explosion";
import { fetchRealWallet } from "state/slices/user-real-wallet-slice.ts";
import { useInovioSuccessfulPaymentRedirectHandler } from "hooks/useInovioSuccessfulPaymentRedirectHandler.ts";

const OrdersManager = () => {
  const dispatch = useAppDispatch();

  const orders = useAppSelector(getOrdersState).orders;

  const auth = useAuth();
  const user = useAppSelector(getUserState);

  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const [showConfetti, setShowConfetti] = useState(false);

  const [notifiedNewlyCompletedOrderIds, setNotifiedNewlyCompletedOrderIds] = useState<string[]>([]);

  useInovioSuccessfulPaymentRedirectHandler({
    whenRedirectedFromPaymentPage: (orderId) => putRequest(`/v2/order/${orderId}/paid`),
    removeInovioParameters: false
  });

  useEffect(() => {
    if (auth.signedIn && user.id) {
      dispatch(fetchOrders());
    }
  }, [dispatch, user.id, auth.signedIn]);

  useEffect(() => {

    const newOrder = findOrderByStatesIn(orders, ["NEW"]);

    if (newOrder && !pollingIntervalRef.current) {
      pollingIntervalRef.current = setInterval(() => dispatch(fetchOrders()), 4000);
    } else if (!newOrder && pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }

    const newCompletedOrders = orders.filter((o) => o.status === "COMPLETED" && !o.notified_on_completion);

    if (newCompletedOrders.length > 0) {
      // confetti
      setShowConfetti(true);
      setTimeout(() => setShowConfetti(false), 4000);

      newCompletedOrders
        .filter(o => !notifiedNewlyCompletedOrderIds.includes(o.id))
        .forEach((o) => {
          let successMsg = "Done!";
          if (o.order_type === "PRODUCT_PURCHASE" && o.product) {
            successMsg = "You just purchased " + o.product.name;
          }
          if (o.order_type === "DEPOSIT_FUNDS") {
            successMsg = `You successfully deposited $${o.price} to your wallet!`;
          }

          const toastId = toast.success(successMsg);
          setTimeout(() => toast.dismiss(toastId), 5000);

          putRequest(`/v2/order/${o.id}/notified-on-completion`);
        });

      dispatch(fetchRealWallet());
      setNotifiedNewlyCompletedOrderIds(newCompletedOrders.map(o => o.id));
    }
  }, [dispatch, orders]);

  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };
  }, []);

  return (
    <div className={"d-flex flex-column justify-content-center align-items-center"} style={{ width: "100%" }}>
      {showConfetti && <ConfettiExplosion
        zIndex={1001}
        particleCount={400}
        duration={4000}
        width={2000}
        style={{
          position: "absolute",
          top: "20%",
          left: "55%"
        }}
      />}
    </div>
  );
};

export default OrdersManager;
