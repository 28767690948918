import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import Spinner from "react-bootstrap/Spinner";
import React from "react";
import { useAppSelector } from "state/hooks.ts";
import { getRealWalletState } from "state/slices/user-real-wallet-slice.ts";

const BalanceDisplay = () => {

  const wallet = useAppSelector(getRealWalletState);

  return (
    <div className="d-flex flex-column align-items-center">
      <BlockyBoldText style={{ color: "white" }}>
        Balance: {wallet.fetchStatusState === "loading" ?
        <Spinner animation="border" size={"sm"} /> : <span style={{
          color: wallet.balance === 0 ? "red" : "white"
        }}>{wallet.formattedBalance}</span>}
      </BlockyBoldText>
    </div>
  );

};

export default BalanceDisplay;