import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";
import { BattlePass, GroupBattlePasses } from "interfaces/battle-pass.ts";

export interface BattlePassesState {
  battlePasses: BattlePass[];
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: BattlePassesState = {
  battlePasses: [],
  fetchStatusState: "loading",
  error: null,
};

export const fetchBattlePasses = createAsyncThunk<
  BattlePass[],
  void,
  { rejectValue: string }
>("battlepass/fetch", async (_, { getState, rejectWithValue }) => {
  try {

    const state = getState() as RootState;
    const groupId = state.group.id;

    if (!groupId) {
      return rejectWithValue("No group ID found in store.");
    }

    return (await getRequest(`/pass/details/group/${groupId}`) as GroupBattlePasses).passes || [];
  } catch (err) {
    console.error(err);
    return rejectWithValue("Failed to fetch products");
  }
});

const slice = createSlice({
  name: "battlepasses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBattlePasses.pending, (state: BattlePassesState) => {
      state.fetchStatusState = "loading";
      state.error = null;
    });
    builder.addCase(fetchBattlePasses.fulfilled, (state: BattlePassesState, action) => {
      state.fetchStatusState = "succeeded";
      state.battlePasses = action.payload;
      state.error = null;
    });
    builder.addCase(fetchBattlePasses.rejected, (state: BattlePassesState, action) => {
      state.fetchStatusState = "failed";
      state.error = action.payload ?? "Something went wrong.";
    });
  },
});

export const getBattlePassesState: (state: RootState) => BattlePassesState = (state: RootState) => state.battlePasses;

export default slice.reducer;
