import React, { useCallback, useEffect, useState } from "react";
import { formatAsCurrency, formatAsDollars } from "utils/formatter-utils/currency-formatter";
import { Transaction } from "interfaces/transaction";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { getRequest } from "utils/httpClient";
import { secondaryColor, sparketBlack, textColor } from "utils/constants";
import useWallet from "hooks/useWallet";
import Header from "components/header";
import LoadingSpinner from "components/loading-spinner.tsx";
import Headroom from "react-headroom";
import { Button, Table } from "react-bootstrap";
import styles from "./styles.module.css";
import { getContestsPath, getJoinedContestsPath, getTransactionsForWalletPath } from "utils/backend-path-builders";
import ContestEntrySelector, {
  ContestEntryOption
} from "components/leaderboard/contest-filter/contest-entry-selector.tsx";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";
import { useAppSelector } from "state/hooks.ts";
import { getGroupState, getGroupStyles } from "state/slices/group-slice.ts";
import { Contest, JoinedContest } from "interfaces/leaderboard/contest.ts";
import { toast } from "react-toastify";
import useQueryParams from "utils/useQueryParams.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import PrizeoutButton from "components/bankroll/prizeout-button.tsx";
import Spinner from "react-bootstrap/Spinner";
import { getRealWalletState } from "state/slices/user-real-wallet-slice.ts";
import { Toggle } from "rsuite";

const SPARKS_BALANCE = "§ Sparks";
const USD_BALANCE = "$ USD";

const constructTransactionLabel = (transaction: Transaction) => {
  if (!transaction.details) {
    return "Unknown details";
  }

  const {
    re_seed,
    reason,
    contestant,
    pool_name,
    prizeout_redeem,
  } = transaction.details;

  switch (transaction.transaction_type) {
    case "REWARD_JOINED_GROUP":
    case "REWARD_CRON_RESET":
    case "REWARD_CRON_ADD":
    case "REWARD_MANUAL_RESET":
    case "REWARD_MANUAL_ADD":
    case "REWARD_JOINED_CONTEST":
    case "REWARD_REFERRAL_1ST_BET":
      if (reason) {
        return re_seed + " - " + reason;
      } else {
        return re_seed;
      }
    case "BET_BOOKING":
    case "SEED_BOOKING":
      return `${contestant} - ${pool_name}`;
    case "BET_BOOKING_WW":
      let prefix = "Sold Bet";
      if (transaction.transaction_amount < 0) {
        prefix = "Purchased Bet";
      }
      return `${prefix}: ${contestant} - ${pool_name}`;
    case "BET_REFUND":
      return `Refund Bet: ${pool_name} - ${contestant}`;
    case "POOL_SETTLEMENT":
      return `WIN: ${contestant} - ${pool_name}`;
    case "POOL_FEES":
      return `Collect fees: ${pool_name}`;
    case "PRIZEOUT_REDEEM":
      return `Prizeout Withdrawal: ${prizeout_redeem}`;
    case "DEPOSIT_FUNDS":
      return "Funds Deposit";
    case "PURCHASE":
      return "Product purchase";
    case "CONTEST_ENTRY":
      return "Contest Entry Fee";
    default:
      return "unknown transaction type";
  }
};

const getFormattedDate = (dateString: string) => {
  const date = new Date(dateString);
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  return month + "/" + day + "/" + year;
};

const Bankroll = () => {

  const [activeWalletType, setActiveWalletType] = useState<string>(SPARKS_BALANCE);

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [transactionData, setTransactionData] = useState<Transaction[]>();
  const [page, setPage] = useState(1);

  const queryParams = useQueryParams();

  const contestEntryIdQueryPar: string | null = queryParams.get("contestEntryId");

  const group = useAppSelector(getGroupState);

  const [contestEntries, setContestEntries] = useState<ContestEntryOption[]>();
  const [selectedContestEntry, setSelectedContestEntry] = useState<ContestEntryOption | undefined>();

  const userHasJoinedTheGroup = group.status === UserGroupMembershipStatus.APPROVED;

  // ===================== GROUP WALLET =====================
  const {
    walletId,
    balance: groupWalletBalance,
    loading: loadingWallet,
    formattedBalance,
    getWalletData
  } = useWallet({ contestEntryId: selectedContestEntry?.contestEntryId });

  // ===================== REAL WALLET =====================
  const realWalletState = useAppSelector(getRealWalletState);
  const {
    walletId: realWalletId,
    formattedBalance: realWalletFormattedBalance,
    fetchStatusState: realFetchStatus,
  } = realWalletState;

  const activeWalletId = activeWalletType === USD_BALANCE ? realWalletId : walletId;

  const activeFormattedBalance = activeWalletType === USD_BALANCE ? realWalletFormattedBalance : formattedBalance;

  useEffect(() => {
    const loadContests = async () => {
      try {
        const [contestsResponse, joinedContestsResponse] = await Promise.all([
          getRequest(getContestsPath(group.id), { skipIntegrationApi: true }) as Promise<Contest[]>,
          getRequest(getJoinedContestsPath(group.id), { skipIntegrationApi: true }) as Promise<JoinedContest[]>,
        ]);

        let entries: ContestEntryOption[] = joinedContestsResponse.flatMap((jc) =>
          jc.entries.map((entry, index) => ({
            contestEntryId: entry.id,
            contestEntryCreatedAt: entry.created_at,
            contestId: jc.contest_id,
            contestName: contestsResponse.find(c => c.id === jc.contest_id)?.name || "Unknown Contest",
          }))
        );

        if (contestEntryIdQueryPar != null) {
          const initialContestEntryOption = entries.find(entry => entry.contestEntryId === contestEntryIdQueryPar);
          setSelectedContestEntry(initialContestEntryOption);
        }

        setContestEntries(entries);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    if (group.id && userHasJoinedTheGroup) {
      if (group.isContestGroup) {
        loadContests();
      } else {
        setContestEntries([]);
      }
    }
  }, [group.id, userHasJoinedTheGroup, group.isContestGroup]);

  const loadTransactions = useCallback(() => {
    if (!activeWalletId) {
      setTransactionData([]);
      return;
    }

    const queryParams = {
      pg: page,
      page_size: 16,
    };

    setLoadingTransactions(true);
    const path = getTransactionsForWalletPath(activeWalletId);
    getRequest(path, { queryParams, skipIntegrationApi: true })
      .then((response: Transaction[] | null) => {
        if (response != null) {
          setTransactionData(response);
        } else {
          setTransactionData([]);
        }
      })
      .finally(() => {
        setLoadingTransactions(false);
      });
  }, [activeWalletId, page]);

  useEffect(() => {
    loadTransactions();
  }, [activeWalletId, page, loadTransactions]);

  const transactions = (transactionData as Transaction[]) || [];
  const sortedTransactions =
    transactions?.sort((a: Transaction, b: Transaction) => {
      return Date.parse(b.created_at) - Date.parse(a.created_at);
    }) || [];

  const isFirstPage = page === 1;

  const handleSelect = (key: string) => {
    setActiveWalletType(key as any);
  };

  return (
    <div className={styles.pageContainer}>
      <Headroom>
        <Header />
      </Headroom>

      <div className={styles.contentContainer}>
        <div style={{ backgroundColor: primaryColor }}>
          <div className={styles.balanceRow}>
            <BlockyHeavyText
              customStyles={{
                fontSize: 22,
                display: "flex",
                alignItems: "center",
                color: textColor,
              }}
            >
              Bankroll
            </BlockyHeavyText>


            <div className={"d-flex flex-row gap-3 align-items-center justify-content-center"}>
              {group.id && group.contest_group &&
                <Toggle
                  className={styles.customToggle}
                  size="lg"
                  defaultChecked={true}
                  checkedChildren={SPARKS_BALANCE}
                  unCheckedChildren={USD_BALANCE}
                  onChange={checked => handleSelect(checked ? SPARKS_BALANCE : USD_BALANCE)} />
              }

              <BlockyBoldText
                customStyles={{
                  display: "flex",
                  alignItems: "center",
                  paddingRight: 7,
                  color: textColor,
                }}
              >
                {loadingWallet ? <Spinner style={{ marginLeft: 15 }} animation="border" /> : activeFormattedBalance}
              </BlockyBoldText>

              {activeWalletType === SPARKS_BALANCE && !selectedContestEntry && (
                <PrizeoutButton
                  balance={groupWalletBalance || 0}
                  onExit={() => {
                    loadTransactions();
                    getWalletData();
                  }}
                />
              )}
            </div>
          </div>

          {activeWalletType === SPARKS_BALANCE && contestEntries && contestEntries.length > 0 && (
            <div style={{ padding: 15 }}>
              <ContestEntrySelector
                contestEntries={contestEntries}
                setEntry={(entry) => {
                  setSelectedContestEntry(entry);
                  // Possibly also pass that to useWallet or reload, depending on your logic
                }}
                disabled={loadingWallet || loadingTransactions}
                selectedEntry={selectedContestEntry}
              />
            </div>
          )}
        </div>

        <div>
          {loadingTransactions || loadingWallet ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <LoadingSpinner />
            </div>
          ) : (
            <Table striped>
              <thead style={{ borderBottom: "2px solid" }}>
              <tr>
                <th className={styles.bankrollTableHeader}>Transaction</th>
                <th className={styles.bankrollTableHeader}>Date</th>
                <th className={styles.bankrollTableHeader}>Amount</th>
              </tr>
              </thead>
              <tbody>
              {sortedTransactions.map((transaction, index) => (
                <tr key={index}>
                  <td>{constructTransactionLabel(transaction)}</td>
                  <td>{getFormattedDate(transaction.created_at)}</td>
                  <td
                    style={{
                      color: transaction.transaction_amount > 0 ? "green" : "red",
                    }}
                  >
                    {`${transaction.transaction_amount > 0 ? "+" : ""}${activeWalletType === SPARKS_BALANCE ?
                      formatAsCurrency(transaction.transaction_amount) : formatAsDollars(transaction.transaction_amount)}`}
                  </td>
                </tr>
              ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>

      <div className={styles.footer}>
        <Button
          style={{
            backgroundColor: secondaryColor,
            border: "none",
            color: sparketBlack,
          }}
          disabled={isFirstPage}
          onClick={() => {
            setLoadingTransactions(true);
            setPage(page - 1);
          }}
        >
          <BlockyHeavyText>Previous Page</BlockyHeavyText>
        </Button>
        <Button
          style={{
            backgroundColor: secondaryColor,
            border: "none",
            color: sparketBlack,
          }}
          disabled={transactions.length < 12}
          onClick={() => {
            setLoadingTransactions(true);
            setPage(page + 1);
          }}
        >
          <BlockyHeavyText>Next Page</BlockyHeavyText>
        </Button>
      </div>
    </div>
  );
};

export default Bankroll;
