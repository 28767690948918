import NavigationLinks from "components/drawer/navigation-links";
import { sparketBlack } from "utils/constants";
import { ReactComponent as SparketLogo } from "icons/sparket-logo.svg";
import { Link } from "react-router-dom";
import FooterContent from "components/drawer/footer-content";
import AdBanner from "components/ad-banner/AddBanner";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import LoadingSpinner from "../loading-spinner.tsx";

const SideBar = () => {

  const groupState = useAppSelector(getGroupState);

  return (
    <div
      style={{
        backgroundColor: sparketBlack,
        width: 270,
        height: "100vh",
        position: "sticky",
        display: "flex",
        flexDirection: "column",
        boxShadow: "1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46)",
        overflow: "auto",
      }}
    >
      <Link to="/">
        <SparketLogo style={{ margin: "1em" }} width={40} height={40} />
      </Link>
      {groupState.loading ? <LoadingSpinner /> : <NavigationLinks />}
      <AdBanner styles={{ marginLeft: 10, marginRight: 10 }} />
      <div style={{ flexGrow: 1 }}></div>
      <div style={{ margin: 10 }}>
        <FooterContent />
      </div>
    </div>
  );
};
export default SideBar;
