import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store.ts";

export interface RedirectFromInovioPageOrderSlice {
  redirectedAt?: number;
  orderCompleted: boolean;
}

export const initialState: RedirectFromInovioPageOrderSlice = {
  redirectedAt: undefined,
  orderCompleted: false,
};

const slice = createSlice({
  name: "appConfig",
  initialState: initialState,
  reducers: {
    setRedirectedFromPaymentPage(state: RedirectFromInovioPageOrderSlice) {
      state.redirectedAt = Date.now();
      state.orderCompleted = false;
    },
    setOrderCompleted(state: RedirectFromInovioPageOrderSlice) {
      state.redirectedAt = undefined;
      state.orderCompleted = true;
    },
  },
});

export const getInovioRedirectOrderIdSlice = (state: RootState): RedirectFromInovioPageOrderSlice => state.inovioRedirectOrderId;

export const { setRedirectedFromPaymentPage, setOrderCompleted } = slice.actions;

export default slice.reducer;
