import { usePoolData } from "hooks/usePoolData";
import LoadingSpinner from "components/loading-spinner.tsx";
import MyContestHeader from "./header";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { getEntryIdsByContestId } from "state/slices/contests-slice.ts";
import { useAppSelector } from "state/hooks.ts";
import { PoolList } from "components/pool-item";
import useContestData from "hooks/useContestData.ts";
import { MyPicksPopout } from "components/contests/my-contests";
import ContestModal from "components/contests/contest-modal";
import useCheckContest from "hooks/useCheckContest.tsx";
import { getGroupState } from "state/slices/group-slice.ts";
import Header from "components/header";
import LoginButtons from "components/login-buttons";
import Headroom from "react-headroom";
import { useInovioSuccessfulPaymentRedirectHandler } from "hooks/useInovioSuccessfulPaymentRedirectHandler.ts";
import { useBetsData } from "hooks/useBetsData";

const ContestPage = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const groupId = useAppSelector(getGroupState).id;

  const navigate = useNavigate();

  const { id: contestId } = useParams<{ id: string }>();

  const { loading: checkingGroup, contest } = useCheckContest(contestId);
  const { loadingContests } = useContestData();

  const { pools, loading: poolLoading } = usePoolData(contestId || "");

  const entryIds = useAppSelector((state) => getEntryIdsByContestId(state, contestId));
  const contestEntryId = entryIds?.[0]; // first contest entry for now
  const { loadingBets, bets } = useBetsData(contestEntryId);

  const isWrongGroup = contest && contest.group_id !== groupId;

  useInovioSuccessfulPaymentRedirectHandler({
    whenRedirectedFromPaymentPage: () => {
      setShowDetailsModal(true);
    },
  });

  if (loadingContests || isWrongGroup || checkingGroup || loadingBets) {
    return <LoadingSpinner />;
  } else if (!contest) {
    navigate("/");
    return;
  }

  return (
    <>
      <Headroom>
        <Header />
        <LoginButtons />
        <MyContestHeader
          contest={contest}
          setShowModal={setShowDetailsModal}
          contestEntryId={contestEntryId}
          bets={bets}
        />
      </Headroom>
        {poolLoading && <LoadingSpinner />} 
        
        {!poolLoading && <PoolList
          pools={pools}
          contestEntryId={contestEntryId}
          contestId={contestId}
          isContestList={true}
          showCountDownClock={true}
        />}

        {contestEntryId && <MyPicksPopout contestEntryId={contestEntryId} />}

        <ContestModal
          contest={contest}
          show={showDetailsModal}
          setShow={setShowDetailsModal}
        />
    </>
  );
};

export default ContestPage;