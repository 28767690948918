import React from "react";

interface PaymentModalSection {
  children: React.ReactNode;
}

const GreyModalSection: React.FC<PaymentModalSection> = ({ children }) => (
  <div
    style={{
      backgroundColor: "#292929",
      padding: "1rem",
      borderRadius: "5px",
      marginBottom: "1rem",
    }}
  >
    {children}
  </div>
);

export default GreyModalSection;
