import React, { FC, SVGProps } from "react";
import styles from "./pulsing-exclamation-mark-icon.module.css";

interface PulsingExclamationMarkIcon extends SVGProps<SVGSVGElement> {
}

const PulsingExclamationMarkIcon: FC<PulsingExclamationMarkIcon> = (props) => {
  const { className, ...restProps } = props;

  return (
    <svg
      {...restProps}
      className={`${styles.pulsate} ${styles.bordered} ${className || ""}`}
      viewBox="0 0 150 150"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <style>
          {`
            .icon-circle {
              fill: red;
            }
            .icon-exclamation {
              fill: black;
            }
          `}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        {/* Circle */}
        <path
          className="icon-circle"
          d="M75,0a75,75,0,1,0,75,75A75,75,0,0,0,75,0Z"
        />

        {/* Dot of the exclamation mark */}
        <path
          className="icon-exclamation"
          d="M75,131.36a12.53,12.53,0,1,1,12.52-12.53A12.57,12.57,0,0,1,75,131.36Z"
        />

        {/* Upper part of the exclamation mark */}
        <path
          className="icon-exclamation"
          d="M89.34,34.24l-4.2,50.22a10.18,10.18,0,0,1-20.29,0L60.66,34.24A14.4,14.4,0,1,1,89.4,33.05a8,8,0,0,1-.06,1.19Z"
        />
      </g>
    </svg>
  );
};

export default PulsingExclamationMarkIcon;
