import { UICustomizations } from "interfaces/ui-customizations";

export interface SectionRemoval {
  section: string;
  row?: number;
}

export interface PageFunctionalityContent {
  title: string;
  body: string[];
}

export const PAGE_FUNCTIONALITY_CONTENT: PageFunctionalityContent[] = [
  {
    title: "Home Page",
    body: [
      "Access the home page anytime by selecting ‘Home’ from the toolbar on the left",
      "The home page shows Open pools available to take picks. It shows the name and the close time of the pool.",
      "Clicking on the arrow icon ’>’ or in the rectangle of a pool brings you to a Pool Page",
      "You can access the menu items on the left panel of the home page, and your profile page on the top right. On a mobile view, you may have to click the menu icon (3 horizontal lines) at the top left to expand the menu",
      "The Terms of Service, Privacy Policy, and Do Not Sell My Personal Information (CCPA) form are available on the bottom left of the menu",
      "If you are not logged in, a banner to Sign in Or Sign Up will appear across the screen",
      "If you are logged in but have not joined the group, a Join Group button will appear near the top right of the home page to bring you to the ‘Join Group’ Page",
    ],
  },
  {
    title: "Sign In Page",
    body: [
      "The sign in page is accessed by clicking ‘Sign In’ from the Home or Pool Pages when you are not signed into the application, or by clicking ‘Sign In’ from the ‘Sign Up’ page",
      "From this page, you can sign in with Google, Facebook by clicking the relevant button, or enter the email and password for your account",
      "If you do not have an account you may click Sign Up to create a new account",
      "You can access the Terms of Service and Privacy policy at the bottom of this page, which you agree to by signing in",
      "If you have forgotten your Password, you can click ‘Forgot Password’ to reset it. This will prompt you for your email address, and you will click submit. This will send you an email with a code, which you can enter along with a new password, and then click ‘Submit’ to reset your password",
    ],
  },
  {
    title: "Sign Up Page",
    body: [
      "The sign up page is accessed by clicking ‘Sign up’ from the Home or Pool Pages when you are not signed into the application, or by clicking ‘Sign up’ from the ‘Sign In’ page",
      "You can access the Terms of Service and Privacy policy at the bottom of this page, which you agree to by signing up",
      "You can create an account using Google, Facebook, or email login. For Google or Facebook you simply click the relevant button",
      "Creating an account with email requires you to enter an email and password. You will then receive a verification email, and must click the link in that email to verify your account.",
    ],
  },
  {
    title: "Edit Profile Page",
    body: [
      "Upon creating a new account from the Sign Up Page and verifying your email account, you are automatically directed to the Edit Profile page to create your profile for the first time. You can also reach this page from the ‘View Profile’ page and selecting ‘Edit Profile’",
      "You can also select a unique username and enter your personal information (First Name, Last Name, country, Postal Code, and birthdate)",
      "Clicking the ‘Submit Profile’ button will save your changes and bring you back to the previous page",
      "There is also a toggle available to enable Two Factor Authentication. If this toggle is disabled, clicking it will popup a page to provide your phone number. Clicking submit will send you a text message code to verify your phone number. Entering that code correctly will verify your phone number and enable two factor authentication for future security purposes. Clicking disable will retain your phone number but disable two factor authentication.",
    ],
  },
  {
    title: "Profile Page",
    body: [
      "You can reach the Profile Page by selecting your avatar in the top right of the user interface",
      "The profile page shows your avatar, email, and all personal information filled in as part of the ‘Profile Edit’ page",
      "Select the ‘Edit Profile’ button to navigate to the ‘Edit Profile Page’",
      "Select the ‘Sign Out’ button to logout from the application",
      "If your identity is verficiated the system will display a message that your identity is verified and display a Green check mark.",
      "If your identity verification failed you will have a link to ‘Try Again’, or if you have not done identity verification you will have the option to ‘Click here’ to begin it. Either link will bring you to the ‘Identity Verification Page’",
      "You can click the ‘Responsible Gaming’ link to bring you to the ‘Responsible Gaming Page’",
      "Select the 'Edit Avatar' button to open a modal allowing you to customize your profile equip special badges and avatars unlocked through Sparket Pass challenges.",
      "Select the 'Unlock Rewards' button to purchase the Sparket Pass, giving you access to unique rewards.",
      "The 'Sparket Skill Score' utilizes advanced Machine Learning algorithms to accurately reflect your true skill in making picks. Keep making successful picks to watch your Sparket Skill Score rise. Be careful though, as losing picks will lower your score. Use your score as a guide to track your progress and identify opportunities to enhance your picking strategy. The 'Sparket Skill Score' utilizes advanced Machine Learning algorithms to accurately reflect your true skill in making picks. Keep making successful picks to watch your Sparket Skill Score rise. Be careful though, as losing picks will lower your score. Use your score as a guide to track your progress and identify opportunities to enhance your picking strategy. A higher score indicates better skill, with 1 being the highest possible, .5 representing average performance, and 0 indicating room for improvement."
    ],
  },
  {
    title: "Edit Avatar Page",
    body: [
      "The Edit Avatar Page can be accessed by clicking the ‘Edit Avatar’ button on the Profile Page",
      "On this page, you can select a unique avatar and badge to represent your profile",
      "These unique rewards are unlocked by purchasing the 'Sparket Pass' and completing unique challenges."
    ]
  },
  {
    title: "Unlock Rewards",
    body: [
      "Selecting the 'Unlock Rewards' button on the Profile Page will open a new tab allowing you to purchase avatar and badge upgrades for your Profile.",
      "Follow the instructions to gain access to unique and exciting challenges and rewards!"
    ]
  },
  {
    title: "Join Group Page",
    body: [
      "The ‘Join Group Page’ can be accessed by clicking the ‘Join Group’ button on the home page when you are logged in but haven’t previously joined the group",
      "Joining a group gives you access to pick on and participate in the pools, leaderboard, and results confirmation of that group. ",
      "The ‘Join Group Page’ will display relevant terms and conditions of joining that group, including data sharing, contest terms (if applicable), and age acknowledgement",
      "You can click the X on the top right, click outside of the box, or ‘Decline’ the terms to close the page without joining the Group",
      "You can click ‘Accept’ to Join the Group",
    ],
  },
  {
    title: "Pool Page",
    body: [
      "A Pool Page displays relevant details for an individual prediction pool. You can reach this page by clicking the arrow icon ‘>’ (or in the rectangle of a pool) from the Home Page or ‘View Pool’ from the ‘Picks Detail Page’",
      "In the upper section, you can see the pool name, the total pool size, the pool status, the pool close time, the pool win condition, and a popup to display full rules for the pool",
      "In the lower section, you can view each contestant in the pool, including their projected win % (based on initial data when the pool was opened) and current Win odds. For pool types like ‘Win’ where you only need to select one contestant, you can also click the arrow icon ‘>’ (or in the rectangle of a contestant) to open the bottom Confirm Pick modal. For pool types where you need to select multiple contestants, you can utilize the bubbles to the right of each contestant card to select the position that you predict that contestant will fill in. Once all bubbles are filled for each position, the bottom Confirm Pick Modal will appear.",
      "You can share the pool with friends via any external messaging or social media platform by clicking the ‘copy link’ icon in the top right. You can navigate back to the home page via the ‘back home’ button on the top left",
      "If the pool status is In Progress or Settled, you will not be able to make a pick, but you will be able to view the final Odds and Active Picks on each contestant. ",
      "If the pool status is Settled, you will be able to see the winner and the outcome (score)",
      "If you are not logged in, a banner to Sign in Or Sign Up will appear across the screen",
      "If geolocation is required, you will see a status message regarding geolocation until it is completed successfully, at which point the message will disappear. If the geolocation is not successful, you will be able to click an ‘info’ button to open the ‘Geolocation Page’",
      "Pool Types: If available for the pool, there will be a toggle above contestants to select various pool types, such as ‘Win’, ‘Exacta’, ‘Trifecta’, and more. You can learn more about these pick types in the Terminology Section."
    ],
  },
  {
    title: "Confirm Pick Modal",
    body: [
      "When the proper contestant selections are made on the Pool Page, a Confirm Pick Modal will appear at the bottom of the page. This modal will display your available balance, the contestant you selected, and the current odds and projected return. These numbers will update as you Enter an Amount for your pick. Once you enter a valid amount, you can ‘Confirm’ your pick using the button."
    ],
  },
  {
    title: "Pick Confirmed Page",
    body: [
      "The Pick Confirmed Page is reached from clicking ‘Confirm’ on the Confirm Pick Modal",
      "The page allows you to copy a link to share the pool on various social media platforms",
      "You can open the ‘Pick Details’ page to see more details about your pick by clicking the ‘here’ link",
      "You can click outside of the Pick Confirmed page to close it",
    ],
  },
  {
    title: "Pick Details",
    body: [
      "You can view the Pick Details to review details on any picks that you’ve made by clicking the Arrow icon ‘>’(or in the rectangle of a pool) on the My Picks page or by clicking the ‘here’ link on the Pick Confirmed Page",
      "You can view the Group that you made the pick in, the pool, the event start and end time, the contestant you picked, the estimated odds, your user id, your pick id, the time you made the pick, your pick amount, and your Projected or Actual Return",
      "You can select the ‘View Pool’ button to go to the Pool Page",
      "You can click outside of the Pick Review to return to the previous screen",
      "If the Pool is Settled, you will see the amount you won if you picked the correct contestant(s), or the amount you lost if you did not",
    ],
  },
  {
    title: "Bankroll Page",
    body: [
      "The Bankroll Page can be reached by selecting the ‘Bankroll’ menu item on the left panel",
      "From this page you can see your available balance and all transactions impacting your balance, including the transaction name and date",
      "If you have sufficient transactions you can select ‘next page’ at the bottom to see additional transactions",
      "After selecting ‘next page’, you can select ‘previous page’ at the bottom to return to the previous page",
    ],
  },
  {
    title: "My Picks Page",
    body: [
      "The My Picks Page can be accessed via the ‘My Picks’ menu  item on the left panel",
      "From this page you can view each pick you have made, including the contestant and pool name, the pool close date, the amount of your pick, the status of the pool or your pick, and either Projected Return or the actual amount won or lost",
      "In Progress picks are highlighted in yellow; winning picks in green, losing picks in red; and open picks are not highlighted",
      "You can select the arrow icon ‘>’ or within the rectangle of the pick to navigate to that pick’s information ",
    ],
  },
  {
    title: "Leaderboard",
    body: [
      "The leaderboard can be accessed via the ‘Leaderboard’ menu item on the left panel",
      "The leaderboard displays your performance against other users within the group that have participated in the same group. You will see a list of the top 100 leaderboard performers and your performance and rank will be highlighted at the top of the list",
      "You can view your overall performance within the group, or if applicable you can filter for various Contests and Phases by utilizing the dropdown filters and buttons at the top of the Leaderboard",
      "The group leaders' equipped 'Sparket Pass' rewards are displayed along with the username. Use this as an oppurtunity to show off your unique rewards and your performance to the rest of the group!",
      "For optimal display on larger screens, you can hit the ‘Fullscreen’ button to display a fullscreen version of the leaderboard. In this version, a QR code will appear which allows users to scan to access the group",
      "You can also click ‘View Contest Terms’ to view the latest Contest Terms within the group",
    ],
  },
  {
    title: "Confirm Results Page",
    body: [
      "The Confirm Results Page can be accessed via the ‘Confirm Results’ menu item on the left panel",
      "On this page you can view all pools which are ‘In Progress’ and report the results of those pools. ",
      "You can proceed to the ‘Confirm Results Pool Page’ by clicking on the arrow icon ‘>’ (or within the rectangle of the pool)",
    ],
  },
  {
    title: "Confirm Results Pool Page",
    body: [
      "The Confirm Results Pool page can be accessed by by clicking on the arrow icon ‘>’ or in the rectangle of a pool ‘Confirm Results’ page",
      "On this page, you will see the same information as appears on the ‘Pool Page’ (see above)",
      "However, on this page when clicking on the arrow icon ‘>’ or within the rectangle of a contestant, it will ask you whether you want to ‘Confirm’ the contestant as the winner",
      "Clicking ‘Confirm’ submits your report that this contestant was the winner of the pool. This is separate from any picks",
      "Clicking on another contestant rectangle or arrow icon before clicking ‘Confirm’ will instead ask you whether to confirm the new contestant as the winner",
      "If you have already confirmed a winner for that pool, your confirmed result will appear with an orange check mark and highlight within the Confirm Results Pool Page",
    ],
  },
  {
    title: "Help Page",
    body: [
      "The Help Page can be accessed via the ‘Help’ menu item on the left panel",
      "This is the section that you’re currently reading! It contains useful information about every function that the application uses, terminology about prediction, responsible gaming information, contact details, supported browsers and more! ",
    ],
  },
  {
    title: "Identity Verification Page",
    body: [
      "You can navigate to the ‘Identity Verification Page’ by clicking the ‘Click here’ to verify your identity link on the Profile page or by clicking ‘Try again’ on the Profile Page after a failed or in progress identity verification attempt",
      "On this page, you can verify your identity for verification purposes ",
      "You provide your phone number, and legal first and last names and click ‘Send SMS’",
      "This brings you to our third party verification service which is on a separate website and walks you through steps to verify your identity depending on regulatory requirements and you documents",
      "Your Profile Page and picture will indicate the status of your identity verification within the application. A successful verification will result in a green check mark, a pending verification will have an orange ellipses, and a failure will have a red exclamation mark",
    ],
  },
  {
    title: "Responsible Gaming Page",
    body: [
      "The ‘Responsible Gaming Page’ can be reached by clicking on the Responsible Gaming link on the ‘Profile Page’",
      "This page allows you to set limits or exclude yourself from real money prediction for a period of time. You can find more resources on Responsible Gaming and what to do if you have a gambling problem in the below section of this Help Page",
      "On this page, you can exclude yourself from real money gambling for a selected period of time by selecting a timeframe and hitting ‘submit’",
      "You can also set limits of either deposits into your account or pick amounts for Daily, Weekly, or Monthly time periods by entering the relevant field and clicking ‘Save’",
    ],
  },
  {
    title: "Geolocation Page",
    body: [
      "The ‘Geolocation Page’ can be accessed by clicking ‘Info’ on the ‘Pool Page’ after an unsuccessful Geolocation check. Geolocation checks will be run automatically by the software as required by the regulatory body",
      "This page provides details about the failed geolocation, and provides a link to ‘Retry’ the geolocation check. Clicking Retry will initiate the check again",
      "This page also may have links to the third party ‘Xpoint’ app to download or launch the software to support your geolocation check. This is required for certain real money gambling purposes",
    ],
  },
  {
    title: "Miscellaneous",
    body: [
      "Functionality in this web application is the same on desktop and mobile devices. However, if your screen is a certain size, you may have to select the menu icon on the top left (three vertical lines), in order to see the left panel menu items referenced above",
      "Available pools are segmented based on ‘Groups’ within the application. Groups have different themes and can be accessed via unique URL links. Once you are a member of multiple groups, you can switch between them by selecting the down arrow next to group logo at the top center of the application.",
    ],
  },
];

export const FUNCTIONALITY_CUSTOMIZATION_MAPPING: { [key in keyof UICustomizations]: SectionRemoval[] } = {
  sign_in_up: [
    { section: "Home Page", row: 5 },
    { section: "Sign In Page" },
    { section: "Sign Up Page" },
    { section: "Join Group Page" }
  ],
  profile_page: [
    { section: "Edit Profile Page" },
    { section: "Profile Page" },
    { section: "Edit Avatar Page" },
    { section: "Upgrade Profile" },
    { section: "Identity Verification Page" },
    { section: "Responsible Gaming Page" },
    { section: "Geolocation Page" }
  ],
  balance: [],
  bankroll_page: [
    { section: "Bankroll Page" }
  ],
  settings_page: [],
  terms_of_service: [
    { section: "Home Page", row: 4 }
  ],
  social_sharing_buttons: [
    { section: "Sign Up Page", row: 1 }
  ],
  confirm_results: [
    { section: "Confirm Results Page" },
    { section: "Confirm Results Pool Page" }
  ],
  leaderboard: [
    { section: "Leaderboard" }
  ],
  help_page: [],
  iframe: [],
  black_text: [],
  contact: [],
};

export const TERMINOLOGY_CUSTOMIZATION_MAPPING: { [key in keyof UICustomizations]: SectionRemoval[] } = {
  sign_in_up: [],
  profile_page: [
    { section: "Purchases"}
  ],
  balance: [],
  bankroll_page: [],
  settings_page: [],
  terms_of_service: [],
  social_sharing_buttons: [],
  confirm_results: [],
  leaderboard: [
    { section: "Pool Status", row: 4 },
  ],
  help_page: [],
  iframe: [],
  black_text: [],
  contact: [],
};


