import { Button } from "react-bootstrap";
import { RewardEnum, secondaryColor, sparketBlack, sparketLightGray } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import AvatarAndBadges from "components/avatar/avatar-display/avatar-and-badges.tsx";
import {
  getAvatarModalState,
  setSelectedAvatar,
  setSelectedBadges,
  setSelectedCategory,
  setShowModal
} from "state/slices/avatar-modal-slice";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getUserState } from "state/slices/user-slice";
import InitializeRewards from "components/avatar/initialize-rewards";
import React, { useEffect, useState } from "react";
import SparketModal from "components/common/modal/modal.tsx";
import EditAvatarDisplay from "components/avatar/modal/edit-avatar-display";
import PurchaseProductModalContent from "components/avatar/modal/upgrade-profile/purchase-product-modal-content.tsx";
import Username from "components/profile/username.tsx";
import { ProductMeta } from "interfaces/commerce/product.ts";
import { getGroupState } from "state/slices/group-slice.ts";
import { getOrdersState } from "state/slices/user-orders-slice.ts";
import { isBattlePassActive } from "utils/battle-pass.ts";
import PulsingExclamationMarkIcon from "components/common/icons/pulsing-exclamation-mark-icon.tsx";
import { fetchProducts, getProductsState } from "state/slices/products-slice.ts";
import { fetchBattlePasses, getBattlePassesState } from "state/slices/battle-passes-slice.ts";
import { BattlePassProduct } from "interfaces/commerce/battle-pass-product.tsx";
import { checkIfUserHasUnseenProducts, Place } from "utils/seen-product-storage.ts";


const AvatarAndBadgesAndOrders = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);
  const orders = useAppSelector(getOrdersState).orders;
  const products = useAppSelector(getProductsState).products;
  const battlePasses = useAppSelector(getBattlePassesState).battlePasses;

  const avatarModalState = useAppSelector(getAvatarModalState);

  const handleEditAvatarButtonClick = () => {
    dispatch(setSelectedAvatar(user.avatar));
    dispatch(setSelectedBadges(user.badges));
    dispatch(setShowModal(true));
  };

  const [showUpgradeProfileModal, setShowUpgradeProfileModal] = useState(false);
  const handleUpgradeProfileButtonClick = () => {
    setShowUpgradeProfileModal(true);
  };

  InitializeRewards();

  const [purchasedOrAvailableForPurchaseBattlePassProducts,
    setPurchasedOrAvailableForPurchaseBattlePassProducts] =
    useState<BattlePassProduct[]>([]);

  useEffect(() => {
    const battlePassProducts = products.reduce((acc, product) => {
      const battlePass = battlePasses
        .find(battlePass => (product.meta as ProductMeta).battle_pass?.id === battlePass.id);

      if (battlePass) {
        acc.push({
          product: product,
          battlePass: battlePass,
          orders: orders.filter((order) => order?.product?.id === product.id),
        } as BattlePassProduct);
      }
      return acc;
    }, [] as BattlePassProduct[]);

    const purchasedOrAvailableForPurchaseBattlePassProducts = battlePassProducts
      .filter(({ battlePass, product }) => isBattlePassActive(battlePass) || product.purchased);

    setPurchasedOrAvailableForPurchaseBattlePassProducts(purchasedOrAvailableForPurchaseBattlePassProducts);
  }, [orders, products, battlePasses]);

  useEffect(() => {
    if (!group.id) {
      return;
    }
    dispatch(fetchProducts());
    dispatch(fetchBattlePasses());
  }, [group.id]);


  const hasProductsAvailableForPurchase: boolean = purchasedOrAvailableForPurchaseBattlePassProducts
    .filter(({ product }: BattlePassProduct) => !product.purchased).length > 0;

  const hasUnseenProducts = checkIfUserHasUnseenProducts(Place.PURCHASE_MODAL, products);

  const notificationIcon = hasProductsAvailableForPurchase && hasUnseenProducts ?
    <PulsingExclamationMarkIcon /> : null;

  const editAvatarModalRef = React.useRef<HTMLDivElement | null>(null);

  return (
    <div className={"d-flex flex-column align-items-center justify-content-center "}>
      <Username username={user.user_name} readonly={false} />

      <AvatarAndBadges avatar={user.avatar} badges={user.badges ?? []} />

      <br></br>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{
            backgroundColor: sparketBlack,
            color: sparketLightGray,
            border: `1px solid ${secondaryColor}`,
            borderRadius: "unset",
            height: 40,
            width: 170,
          }}
          onClick={handleEditAvatarButtonClick}
        >
          <BlockyBoldText>Edit Avatar</BlockyBoldText>
        </Button>

        {
          purchasedOrAvailableForPurchaseBattlePassProducts.length > 0 &&
          <div>
            <Button
              className={"position-relative"}
              style={{
                backgroundColor: secondaryColor,
                color: sparketBlack,
                border: "none",
                borderRadius: "unset",
                height: 40,
                width: 170,
              }}
              onClick={handleUpgradeProfileButtonClick}
            >
              <BlockyBoldText>
                Unlock Rewards
              </BlockyBoldText>

              <div className="position-absolute" style={{ top: -2, right: 2 }}>
                {notificationIcon && React.cloneElement(notificationIcon, {
                  width: 20,
                  height: 20,
                })}
              </div>
            </Button>
          </div>
        }
      </div>

      <SparketModal show={avatarModalState.showModal}
                    onClose={() => {
                      dispatch(setSelectedCategory(RewardEnum.AVATAR));
                      dispatch(setShowModal(false));
                    }}
                    title={"Edit Avatar"}
                    bodyRef={editAvatarModalRef}>
        <EditAvatarDisplay containerRef={editAvatarModalRef} />
      </SparketModal>

      <SparketModal show={showUpgradeProfileModal}
                    onClose={() => setShowUpgradeProfileModal(false)}
                    title={"Unlock Rewards"}>
        <PurchaseProductModalContent battlePassProducts={purchasedOrAvailableForPurchaseBattlePassProducts}
                                     hasProductsAvailableForPurchase={purchasedOrAvailableForPurchaseBattlePassProducts.length > 0}
                                     onClose={() => {
                                       dispatch(fetchProducts());
                                       setShowUpgradeProfileModal(false);
                                     }} />
      </SparketModal>

    </div>

  );
};

export default AvatarAndBadgesAndOrders;