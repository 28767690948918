import React, { useCallback, useEffect, useState } from "react";
import { UserPaymentMethod } from "interfaces/commerce/user-payment-method.ts";
import SparketModal from "components/common/modal/modal.tsx";
import { sparketLightGray } from "utils/constants.ts";
import PaymentMethodsSelector from "components/commerce/payment-methods-selector.tsx";
import AmountSelector from "components/commerce/amount-selector.tsx";
import { toast } from "react-toastify";
import NavigateToInovioHostedPaymentPageButton
  from "components/payment-provider/inovio/navigate-to-inovio-hosted-payment-page-button.tsx";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { useAppDispatch, useAppSelector } from "state/hooks.ts";
import { getPaymentMethodsState, PaymentMethodsState } from "state/slices/user-payment-methods-slice.ts";
import { LongButton } from "components/contests/contest-display-items/shared/buttons";
import BlockyLightText from "components/custom-texts/blocky-light-text.tsx";
import GreyModalSection from "components/commerce/grey-modal-section.tsx";
import { fetchRealWallet } from "state/slices/user-real-wallet-slice.ts";
import { postRequest } from "utils/httpClient.ts";
import { CreateOrderRequest } from "interfaces/commerce/order/create-order-request.ts";
import BalanceDisplay from "components/common/real-money-wallet/balance-display.tsx";
import { fetchOrders } from "state/slices/user-orders-slice.ts";

interface PaymentModalProps {
  show: boolean;
  onClose: () => void;
}

const amountOptions = [5, 20, 100];

const DepositFundsModal = ({
                             show,
                             onClose,
                           }: PaymentModalProps) => {

  const [processing, setProcessing] = useState(false);

  const paymentMethodsState: PaymentMethodsState = useAppSelector(getPaymentMethodsState);
  const paymentMethods = paymentMethodsState.items;

  const dispatch = useAppDispatch();

  const [selectedAmount, setSelectedAmount] = useState(amountOptions[0]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<UserPaymentMethod>();

  const createOrder = useCallback((amount: number, userPaymentMethod?: UserPaymentMethod) => {
    return postRequest("/v2/order", {
      body: {
        amount: amount,
        userPaymentMethodId: userPaymentMethod?.id,
        orderType: "DEPOSIT_FUNDS",
        successPathname: window.location.pathname
      } as CreateOrderRequest
    });
  }, []);

  useEffect(() => {
    if (
      paymentMethodsState.fetchPaymentMethodsStatus === "succeeded" &&
      paymentMethods.length > 0
    ) {
      const defaultMethod = paymentMethods.find((method) => method.is_default);
      setSelectedPaymentMethod(defaultMethod || paymentMethods[0]);
    }
  }, [paymentMethodsState.fetchPaymentMethodsStatus, paymentMethods.length]);

  const handleDirectPaymentButtonClick = () => {
    setProcessing(true);

    createOrder(selectedAmount, selectedPaymentMethod)
      .catch(() => toast.error("Something went wrong. Please contact support."))
      .finally(() => {
        dispatch(fetchRealWallet());
        dispatch(fetchOrders());
        onClose();
        setProcessing(false);
      });
  };

  const buttonTitle = "Deposit";

  return (
    <SparketModal title={"Deposit Funds"} show={show} onClose={onClose}>
      <div style={{ position: "relative" }}>
        {processing && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(0, 0, 0, 0.5)",
              zIndex: 10,
            }}
          />
        )}

        <GreyModalSection>
          <BalanceDisplay />
        </GreyModalSection>

        <GreyModalSection>
          <AmountSelector
            presetAmounts={amountOptions}
            selectedAmount={selectedAmount}
            setSelectedAmount={(amount) => setSelectedAmount(amount)}
          />
        </GreyModalSection>

        {paymentMethods.length > 0 && (
          <GreyModalSection>
            <PaymentMethodsSelector
              paymentMethods={paymentMethods}
              selectedPaymentMethod={selectedPaymentMethod}
              onSelectPaymentMethod={(method) => setSelectedPaymentMethod(method)}
            />
          </GreyModalSection>
        )}

        <div className="d-flex flex-column align-items-center">
          {selectedPaymentMethod ? (
            <LongButton
              text={selectedAmount ? buttonTitle + " $" + selectedAmount : buttonTitle}
              disabled={processing || selectedAmount === undefined || selectedAmount === 0}
              onClick={handleDirectPaymentButtonClick}
            />
          ) : (
            <NavigateToInovioHostedPaymentPageButton
              provideInovioHostedPaymentPageURL={() =>
                createOrder(selectedAmount, selectedPaymentMethod)
                  .then(o => o.payment_page_url)
              }
              title={selectedAmount ? buttonTitle + " $" + selectedAmount : buttonTitle}
              disabled={!selectedAmount}
              setLoading={(loading) => setProcessing(loading)}
              onRedirecting={() => onClose()}
            />
          )}

          <div
            className="d-flex flex-column align-items-center mt-2"
            style={{
              color: sparketLightGray
            }}
          >
            {processing &&
              <div>
                <BlockyBoldText
                  className="text-center m-2"
                  style={{ color: "white", fontSize: "1.2rem" }}
                >
                  Processing...
                </BlockyBoldText>
              </div>}

            {!processing && <div>
              {selectedPaymentMethod ?
                <BlockyLightText>
                  Your account {selectedPaymentMethod.last4} will be charged by Sparket.
                </BlockyLightText> :
                <BlockyLightText>
                  You will be navigated to the hosted payment page
                </BlockyLightText>
              }
            </div>}
          </div>
        </div>
      </div>
    </SparketModal>
  );
};

export default DepositFundsModal;
