import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";
import { getOrdersPath } from "utils/backend-path-builders.ts";
import { Order } from "interfaces/commerce/order/order.ts";

export interface OrdersState {
  orders: Order[];
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: OrdersState = {
  orders: [],
  fetchStatusState: "loading",
  error: null,
};

export const fetchOrders = createAsyncThunk<
  Order[],
  void,
  { rejectValue: string }
>("orders/fetch", async (_, { getState, rejectWithValue }) => {
  try {

    return await getRequest(getOrdersPath()) || [];
  } catch (err) {
    console.error(err);
    return rejectWithValue("Failed to fetch orders");
  }
});

const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    clearOrdersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrders.pending, (state) => {
      state.fetchStatusState = "loading";
      state.error = null;
    });
    builder.addCase(fetchOrders.fulfilled, (state, action) => {
      state.fetchStatusState = "succeeded";
      state.orders = action.payload;
      state.error = null;
    });
    builder.addCase(fetchOrders.rejected, (state, action) => {
      state.fetchStatusState = "failed";
      state.error = action.payload ?? "Something went wrong.";
    });
  },
});

export const getOrdersState: (state: RootState) => OrdersState = (state: RootState) => state.orders;

export const { clearOrdersState } = slice.actions;

export default slice.reducer;
