import { sparketBlack } from "utils/constants.ts";
import { Contest } from "interfaces/leaderboard/contest.ts";
import ContestItem from "./contest-item.tsx";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import { useNavigate } from "react-router-dom";

interface ContestItemProps {
  contest: Contest;
}

const ContestItemHome = ({ contest }: ContestItemProps) => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contest/${contest.id}`, { state: { contest: contest } })
  }

  return (
    <>
      <div
        style={{
          marginLeft: 20,
          marginRight: 20,
          marginBottom: 20,
          backgroundColor: sparketBlack,
          boxShadow: "1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46)",
          cursor: "pointer",
          borderRadius: 10
        }}
        onClick={handleClick}
      >
          <ContestItem contest={contest}/>
      </div>
    </>
  )
};

export default ContestItemHome;
