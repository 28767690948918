import { Modal } from "react-bootstrap";
import React, { ReactNode } from "react";
import styles from "components/common/modal/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { X } from "react-bootstrap-icons";

interface SparketModalProps {
  title: string;
  show: boolean;
  onClose: () => void;
  children: ReactNode;
  bodyRef?: React.MutableRefObject<HTMLDivElement | null>;
}

const SparketModal = ({ title, show, onClose, children, bodyRef }: SparketModalProps) =>
  (
    <Modal show={show} onHide={onClose} dialogClassName={styles.modal}>
      <Modal.Header className={styles.modalHeader}>
        <Modal.Title className={styles.modalTitle}>
          <BlockyHeavyText>{title}</BlockyHeavyText>
          <X size={40} style={{ cursor: "pointer" }} onClick={onClose} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body ref={bodyRef} className={styles.modalBody}>
        {children}
      </Modal.Body>
    </Modal>
  );

export default SparketModal;
