import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { sparketBlack, sparketGold, sparketLightGray } from "utils/constants.ts";
import CurrencyInput from "react-currency-input-field";

interface AmountSelectorProps {
  presetAmounts: number[];
  setSelectedAmount: (amount: number) => void;
  selectedAmount: number;
}

const AmountSelector: React.FC<AmountSelectorProps> = ({
                                                         presetAmounts,
                                                         setSelectedAmount,
                                                         selectedAmount,
                                                       }) => {

  const [mode, setMode] = useState<"preset" | "custom">(presetAmounts.length > 0 ? "preset" : "custom");

  const [customAmountText, setCustomAmountText] = useState("");

  const setCustomAmountAsSelectedAmount = (customAmountString: string) => {
    const parsedValue = parseFloat(customAmountString);
    if (!isNaN(parsedValue)) {
      setSelectedAmount(parsedValue);
    } else {
      setSelectedAmount(0);
    }
  };

  const handleAmountChange = (value: string) => {
    setCustomAmountText(value);
    setCustomAmountAsSelectedAmount(value);
  };

  return (
    <>
      {presetAmounts.length === 1 ? (
        <div className="text-center mb-4">
          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "500",
              color: "#FFFFFF",
            }}
          >
            Amount: ${presetAmounts[0]}
          </p>
        </div>
      ) : (
        <>
          <h5
            className="mb-3 text-white text-center"
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            Select Amount to Deposit
          </h5>
          <Row className="mb-4">
            {presetAmounts.map((amount) => {
              const isSelected = mode === "preset" && selectedAmount === amount;
              return (
                <Col xs={4} key={amount} className="mt-2">
                  <Button
                    variant="outline-secondary"
                    className="w-100"
                    style={{
                      color: sparketBlack,
                      fontWeight: isSelected ? "bolder" : "600",
                      borderRadius: "0",
                      backgroundColor: sparketLightGray,
                      border: "none",
                      outline: isSelected ? `4px solid ${sparketGold}` : `1px solid white`,
                    }}
                    onClick={() => {
                      setMode("preset");
                      setSelectedAmount(amount);
                    }}
                  >
                    ${amount}
                  </Button>
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <CurrencyInput value={customAmountText}
                             prefix={"$"}
                             inputMode={"decimal"}
                             maxLength={5}
                             placeholder={"$ other amount"}
                             allowDecimals={true}
                             className={"w-100"}
                             style={{
                               fontWeight: mode === "custom" ? "bolder" : "600",
                               outline: mode == "custom" ? `4px solid ${sparketGold}` : `1px solid white`,
                               border: "none",
                               borderRadius: "0",
                               backgroundColor: sparketLightGray,

                             }}
                             onFocus={() => {
                               setMode("custom");
                               setCustomAmountAsSelectedAmount(customAmountText);
                             }}
                             onValueChange={(value) => {
                               if (value != undefined) {
                                 handleAmountChange(value);
                                 setMode("custom");
                               } else {
                                 handleAmountChange("");
                               }
                             }} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default AmountSelector;
