import OpenSansText from "components/custom-texts/open-sans-text";
import { LineBreak } from "./highlights";
import darkStyles from "../dark-styles.module.css";

import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "../accordion/accordion-header";
import { filterContent } from "./filter-content";
import { getGroupState } from "state/slices/group-slice";
import { useAppSelector } from "state/hooks";
import { TERMINOLOGY_CUSTOMIZATION_MAPPING } from "./page-functionality-constants";

const CONTENT = [
  {
    title: "Pool Prediction",
    body: [
      "Pool prediction (pari-mutuel) is a prediction style popularized by horse racing in which the odds for each contestant are set based on the volume of picks on each contestant compared to the entire prediction pool. For example, if a prediction pool has 100 in Active Picks, and a specific contestant has 10 in Active Picks, the contestant’s odds will be 100/10 = 10x. See Decimal Odds",
      "Odds update based on actual pick volumes and are not finalized until all picks have been accepted and the pool has closed. This means that your pick amount directly affects the odds in the pool, and future picks after you place your pick will also impact your odds.",
      "Pool operators and/or regulators take a flat commission off any return in the pool",
    ],
  },
  {
    title: "Pool Status",
    body: [
      "Prediction Pools have various statuses displayed in the user interface which indicate which stage the pool is in:",
      "Open: this pool is currently open and accepting picks",
      "In Progress: The pool has closed to accepting new picks and is waiting for results to be reporting (e.g. the event is happening). You may be able to sell this pick via the Sell Pick Button on the ‘My Picks Page’ (Only available for some groups and event types)",
      "Settled: the winning contestant in a pool has been reported and winners have been awarded accordingly",
      "The leaderboard calculation is based on your profit across pools that have been settled or picks that have been sold. It calculates the amount you won minus the amount you pick for standard picks, and will factor in the amount you bought or sold a pick for if applicable",
    ],
  },
  {
    title: "Pool Size",
    body: [
      "The total amount of pick volume currently accepted on a specific pool or contestant",
    ],
  },
  {
    title: "Pool Types",
    body: [
      "Certain pools allow you to select more than just one contestant to win. These pool types like place, show, exacta, trifecta, and more are still pool-based. In Exacta pools you must select the first and second place contestants in exact order, and in Trifecta pools you must select the top 3 contestants in order. Further rules and pool types may be described on each pool’s page"
    ],
  },
  {
    title: "Purchases",
    body: [
      "You can purchase various avatars and badges to Upgrade your profile by entering the ‘Unlock Rewards’ section of the Profile Page. ",
      "Upgrading will bring you to a payments screen where you can provide payment information to purchase your new avatar or badge. You will be able to view a preview of the avatar and badge prior to purchase. These purchased upgrades have no cash or redemption value, nor do they increase the chance of winning any contest or promotion that we run.",
      "If for any reason you are not satisfied with your purchase, you can contact support@betsparket.com to receive credit for a future purchase at any time within 30 days. If there is an issue with your purchase such as a technical glitch or display issue, you may contact us within 30 days for a full refund back to your original form of payment. "
    ]
  },
  {
    title: "Current Odds",
    body: [
      "The Current Odds on a contestant based on the volumes in an open pari-mutuel pool. These odds are subject to change prior to the pool closing.",
    ],
  },
  {
    title: "Decimal Odds",
    body: [
      "Odds are indicated as Decimal, meaning the return received from 1 multiplied by the fraction. For example a 1 pick on a 10x Odds would yield a total return of 10 (profit of 9 and original pick of 1)",
    ],
  },
  {
    title: "Projected Return",
    body: [
      "The Projected Return of a pari-mutuel pool. Note that this amount may change if the pool is still Open. See Current Odds",
    ],
  },

  {
    title: "Commission",
    body: [
      "The percentage of the return that an operator and/or regulator takes out of a pool. This amount is automatically reflected in Odds and Projected Returns.",
    ],
  },
];

const BodyContent = ({ content }: any) => {
  return (
    <>
      {content.map((paragraph: string, index: number) => {
        const isLast = index === content.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{paragraph}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

const Terminology = () => {

  const customization = useAppSelector(getGroupState).customization;
  const filteredContent = filterContent(customization, CONTENT, TERMINOLOGY_CUSTOMIZATION_MAPPING)

  return (
    <>
      <Accordion>
        {filteredContent.map((page, index) => {
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                styles={darkStyles}
                backgroundColor="var(--sparket-black)"
              >
                {page.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <BodyContent content={page.body} />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default Terminology;
