import { Button } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { Product } from "interfaces/commerce/product";
import BattlePassProductItem from "components/avatar/modal/upgrade-profile/battle-pass-product-item";

import { hasActive } from "payment-provider/inovio/inovio";
import { isBattlePassActive } from "utils/battle-pass.ts";
import { secondaryColor, sparketBlack } from "utils/constants.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { toast } from "react-toastify";
import { getPaymentMethodsState, PaymentMethodsState } from "state/slices/user-payment-methods-slice.ts";
import PurchaseButton from "components/commerce/PurchaseButton.tsx";
import GreyModalSection from "components/commerce/grey-modal-section.tsx";
import { getRealWalletState, RealWalletState } from "state/slices/user-real-wallet-slice.ts";
import DepositFundsModal from "components/commerce/deposit-funds-modal.tsx";
import { fetchOrders } from "state/slices/user-orders-slice.ts";
import BalanceDisplay from "components/common/real-money-wallet/balance-display.tsx";
import { fetchRewards } from "components/avatar/initialize-rewards.tsx";
import { BattlePassProduct } from "interfaces/commerce/battle-pass-product.tsx";
import { memorizeSeenProducts, Place } from "utils/seen-product-storage.ts";
import { getProductsState } from "state/slices/products-slice.ts";

interface UpgradeProfileModalContentProps {
  onClose: () => void;
  battlePassProducts: BattlePassProduct[];
  hasProductsAvailableForPurchase: boolean;
}

const PurchaseProductModalContent = ({
                                      onClose,
                                      battlePassProducts,
                                      hasProductsAvailableForPurchase
                                    }: UpgradeProfileModalContentProps) => {

  const dispatch = useAppDispatch();

  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const wallet: RealWalletState = useAppSelector(getRealWalletState);
  const products = useAppSelector(getProductsState).products;

  const paymentMethodsState: PaymentMethodsState = useAppSelector(getPaymentMethodsState);
  const paymentMethods = paymentMethodsState.items;

  const [showDepositFundsModal, setShowDepositFundsModal] = useState(false);

  useEffect(() => {
    memorizeSeenProducts(Place.PURCHASE_MODAL, products);
  }, [products]);

  useEffect(() => {
    const firstProductWithoutActiveOrder =
      battlePassProducts.find(it => !it.product.purchased && !hasActive(it.orders) && isBattlePassActive(it.battlePass));
    if (firstProductWithoutActiveOrder && !selectedProduct) {
      setSelectedProduct(firstProductWithoutActiveOrder.product);
    }
  }, [battlePassProducts]);

  const openDepositFundsModalButton = <div className={"d-flex flex-column justify-content-center align-items-center"}>
    <Button
      variant="primary"
      disabled={!selectedProduct}
      className="mt-2"
      onClick={() => setShowDepositFundsModal(true)}
      style={{
        backgroundColor: secondaryColor,
        color: sparketBlack,
        border: "none",
        borderRadius: "unset",
        height: 40,
        width: 250,
      }}
    >
      <BlockyBoldText>Deposit Funds</BlockyBoldText>
    </Button>
  </div>;

  return (
    <div>
      {hasProductsAvailableForPurchase &&
        <GreyModalSection>
          <BalanceDisplay />
        </GreyModalSection>
      }

      {battlePassProducts
        .map(({ battlePass, product, orders }) => (
          <BattlePassProductItem
            key={battlePass.id}
            battlePass={battlePass}
            product={product}
            productOrders={orders}
            isSelected={selectedProduct?.id === product?.id}
            onClick={() => {
              if (!hasActive(orders)) {
                setSelectedProduct((prevSelected) => prevSelected?.id === product!.id ? undefined : product!);
              }
            }} />
        ))}
      {
        hasProductsAvailableForPurchase &&
        <div>
          {paymentMethods && wallet.fetchStatusState === "succeeded" &&
            <div>

              {
                selectedProduct &&
                <div>
                  {wallet.balance && wallet.balance >= selectedProduct.price ?
                    <PurchaseButton
                      product={selectedProduct}
                      onSuccessfulPurchase={() => {
                        onClose();
                        fetchRewards(dispatch);
                        dispatch(fetchOrders());
                      }}
                      onErrorPurchase={error => {
                        if (error.code === "INSUFFICIENT_FUNDS") {
                          toast.error("Insufficient Funds");
                        } else {
                          toast.error("Something went wrong");
                        }
                      }}
                    /> :
                    openDepositFundsModalButton}
                </div>
              }

              <DepositFundsModal
                show={showDepositFundsModal}
                onClose={() => {
                  onClose();
                  setShowDepositFundsModal(false);
                }}
              />
            </div>}
        </div>
      }
    </div>
  );
};
export default PurchaseProductModalContent;
