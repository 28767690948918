import Home from "home/home";
import { Route, Routes, useLocation } from "react-router-dom";
import Bankroll from "components/bankroll";
import ProfilePage from "components/profile/profilePage.tsx";
import Drawer from "components/drawer";
import "rsuite/dist/rsuite.min.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SideBar from "components/sidebar";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close } from "state/slices/drawer-slice";
import ForgotPassword from "components/forgot-password";
import SparketTutorial from "components/sparket-tutorial";
import LeaderboardPage from "components/leaderboard-page";
import LegalPage from "components/legal-page";
import ConfirmResults from "components/confirm-results";
import ConfirmResultsPoolDetails from "components/confirm-results/pool-details";
import {
  fetchGroupCustomization,
  getGroupSecondaryColorOrDefault,
  getGroupState,
  getGroupStyles,
  setIsContestGroup
} from "state/slices/group-slice";
import ReactGA4 from "react-ga4";
import React, { useEffect } from "react";
import InitializeGroupState from "app-setup/initialize-group-state";
import InitializeGeolocation from "app-setup/initialize-geolocation";
import InitializeUserState from "app-setup/initialize-user-state";
import CheckGroupStatus from "app-setup/check-group-status";
import LegalModal from "components/legal-modal";
import ExternalIdModal from "components/external-id-form";
import LoginModal from "components/profile/login-modal";
import TwoFactorModal from "components/profile/two-factor-auth";
import { getPrivacyPolicyPath, getTermsOfServicePath, } from "utils/backend-path-builders";
import ResponsibleGaming from "./components/responsible-gaming";
import UnsupportedBrowserAlert from "components/unsupported-browser-alert";
import OfflineAlert from "components/offline-alert";
import { getUserState } from "state/slices/user-slice";
import { WagerWireIframe, WagerWireProvider } from "@wagerwire/embed/react";
import { useAuth } from "hooks/auth";
import ContestPopup from "components/contest-popup/modal";
import { getIntegratedAppState, removeIntegratedState, } from "state/slices/integrated-app-slice";
import NotificationSettings from "components/notification-settings";
import {
  APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY,
  checkIfAppConfigResetIsRequired,
  defaultAppConfig,
  getAppConfigOverridesFromLocalStorage,
  getAppConfigOverridesFromQueryParams,
  setAppConfig,
} from "state/slices/app-config-slice";
import { INTEGRATED_APP_HOME_PAGE_URL_PREFIX } from "utils/integration/constants";
import { useIsIntegratedAppHomePage } from "utils/integration/integrated-app-tools";
import Help from "components/help";
import IdentityVerificationPage from "components/profile/identity-verification/identityVerificationPage.tsx";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";
import ContestHome from "./components/contests";
import ContestPage from "./components/contests/contest-details";
import UserPage from "components/user/user-page.tsx";
import MyPicksPage from "components/my-bets/my-picks-page.tsx";
import PoolPage from "components/pool-details/pool-page.tsx";
import { fetchPaymentMethods } from "state/slices/user-payment-methods-slice.ts";
import { fetchRealWallet } from "state/slices/user-real-wallet-slice.ts";
import { fetchOrders } from "state/slices/user-orders-slice.ts";
import OrdersManager from "components/profile/orders/orders-manager.tsx";
import { fetchProducts } from "state/slices/products-slice.ts";
import { fetchBattlePasses } from "state/slices/battle-passes-slice.ts";

ReactGA4.initialize("G-W4C48RRRY7");

const App = () => {
  const excludeNavigationPaths = [
    "/forgot-password",
    "/privacy-policy",
    "/terms-of-service",
  ];

  const excludeBackgroundColorPaths = [
    "/forgot-password",
    "/privacy-policy",
    "/terms-of-service",
    "/help",
    "/edit-profile",
    "/profile",
    "/bankroll",
    "/leaderboard",
    "/responsible-gaming",
    "/notification-settings",
  ];

  const auth = useAuth();
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);
  const location = useLocation();
  const groupHasLoaded = group.id !== "";
  const showNavigation = !excludeNavigationPaths.includes(location.pathname);
  const showBackgroundColor =
    !excludeBackgroundColorPaths.includes(location.pathname) && groupHasLoaded;

  const userId = user.id;

  if (group.settings.enable_geolocation && userId) {
    InitializeGeolocation(userId);
  }

  // TODO once we move away from storing is integrated app var in local storage we'll be able to remove it
  const embeddedInIframe = window !== window.top;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!embeddedInIframe) {
      dispatch(removeIntegratedState());
    }
  }, [dispatch, embeddedInIframe]);

  const isIntegratedAppHomePage = useIsIntegratedAppHomePage();

  // need this condition after leaving home page
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  if (!isIntegratedApp && !isIntegratedAppHomePage) {
    InitializeGroupState(location.pathname);
    InitializeUserState();
  }

  CheckGroupStatus();

  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Page View",
    });
  }, [location]);

  const wagerWireEnabled = !!group.settings.wager_wire;
  const { isMobile } = useDeviceDimensions();

  useEffect(() => {
    dispatch(close());
  }, [dispatch, isMobile]);

  useEffect(() => {
    if (auth.signedIn && user.id) {
      dispatch(fetchPaymentMethods());
      dispatch(fetchRealWallet());
      dispatch(fetchOrders());
      dispatch(fetchProducts());
      dispatch(fetchBattlePasses());
    }
  }, [dispatch, user.id, auth.signedIn]);

  useEffect(() => {
    if (auth.signedIn && user.id && group.id) {
      dispatch(fetchProducts());
      dispatch(fetchBattlePasses());
    }
  }, [dispatch, user.id, auth.signedIn, group.id]);

  useEffect(() => {
      if (!isIntegratedApp && !isIntegratedAppHomePage && groupHasLoaded) {
        dispatch(fetchGroupCustomization(group.id));
      }
    },
    [
      dispatch,
      group.id,
      isIntegratedApp,
      isIntegratedAppHomePage,
      groupHasLoaded]);

  const handleAppConfigChange = () => {
    if (checkIfAppConfigResetIsRequired()) {
      console.log("resetting app config to defaults", defaultAppConfig);
      dispatch(setAppConfig(defaultAppConfig));
      localStorage.removeItem(APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY);
    } else {
      const appConfigOverrides = getAppConfigOverridesFromQueryParams();
      if (Object.keys(appConfigOverrides).length > 0) {
        console.log("applying app config overrides", appConfigOverrides);

        dispatch(setAppConfig(appConfigOverrides));

        const storedOverrides = getAppConfigOverridesFromLocalStorage();
        localStorage.setItem(
          APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY,
          JSON.stringify({
            ...storedOverrides,
            ...appConfigOverrides,
          })
        );
      }
    }
  };

  useEffect(() => handleAppConfigChange(), [dispatch, location]);

  const conditionalStyles = isMobile
    ? {}
    : {
      alignSelf: "flex-end",
      height: "100vh",
      overflow: "scroll",
      width: "100%",
    };

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const backgoundStyles = showBackgroundColor
    ? {
      backgroundImage: `linear-gradient(to right, ${primaryColor} , black)`,
    }
    : { backgroundColor: "#F1F2F2" };

  const showLogin = group.customization.sign_in_up;
  const showBankroll = group.customization.bankroll_page;
  const showLeaderboard = group.customization.leaderboard;

  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  const root = document.documentElement;
  root.style.setProperty("--secondary-color", secondaryColor);

  const isBlackText = group.customization.black_text;
  root.style.setProperty("--text-color", isBlackText ? "black" : "white");

  const contestGroup = group.contest_group;

  useEffect(() => {
    if (!group.id) {
      return;
    }
    dispatch(setIsContestGroup(contestGroup));
  }, [group.id, contestGroup]);

  const HomePage = contestGroup ? ContestHome : Home;

  return <WagerWireProvider
    userId={auth.signedIn && group.status === UserGroupMembershipStatus.APPROVED ? userId : ""}
    embedOrigin={process.env.REACT_APP_WAGER_WIRE_EMBED_ORIGIN || ""}
  >
    <div style={{ display: isMobile ? "initial" : "flex" }}>
      {showNavigation && <>{isMobile ? <Drawer /> : <SideBar />}</>}
      <div
        style={{
          minHeight: "100vh",
          ...backgoundStyles,
          ...conditionalStyles,
        }}
      >

        <OrdersManager />

        <Routes>
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/user/:id" element={<UserPage />} />
          <Route path="/edit-profile" element={<IdentityVerificationPage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/mypicks" element={<MyPicksPage />} />
          <Route path="/bets/:id" element={<MyPicksPage />} />
          <Route path="/pools/:id" element={<PoolPage />} />
          <Route path="/help" element={<Help />} />
          {showBankroll && <Route path="/bankroll" element={<Bankroll />} />}
          {showLeaderboard && <Route path="/leaderboard" element={<LeaderboardPage />} />}
          <Route path="/privacy-policy" element={<LegalPage url={getPrivacyPolicyPath()} />} />
          <Route path="/terms-of-service" element={<LegalPage url={getTermsOfServicePath()} />} />
          <Route path="/notification-settings" element={<NotificationSettings />} />
          <Route path="/confirm-results" element={<ConfirmResults />} />
          <Route path="/pools/:id/confirm" element={<ConfirmResultsPoolDetails />} />
          <Route path="/responsible-gaming" element={<ResponsibleGaming />}></Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/g/:groupSlug" element={<HomePage />} />
          <Route path="/contest/:id" element={<ContestPage />} />
          <Route path={`/${INTEGRATED_APP_HOME_PAGE_URL_PREFIX}/:groupSlug`} element={<HomePage />} />
        </Routes>
        <SparketTutorial />
        <LegalModal />
        <ExternalIdModal />
        {showLogin && <LoginModal />}
        <TwoFactorModal />
        {groupHasLoaded && !group.customization.iframe && <UnsupportedBrowserAlert />}
        <OfflineAlert />
        <ContestPopup />
      </div>
    </div>
    {wagerWireEnabled && <WagerWireIframe />}
  </WagerWireProvider>;
};

export default App;
