import { Product } from "interfaces/commerce/product.ts";

export enum Place {
  PROFILE_PAGE = "PROFILE_PAGE",
  PURCHASE_MODAL = "PURCHASE_MODAL",
}

export type ProductTracking = {
  [key in Place]: string[];
};

const LOCAL_STORAGE_KEY = "sparket_main_app_v1_user_seen_products";

const getDefaultTrackingData = (): ProductTracking => {
  return Object.values(Place).reduce((acc, place) => {
    acc[place as Place] = [];
    return acc;
  }, {} as ProductTracking);
};

const getTrackingData = (): ProductTracking => {
  const storedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  return storedData ? JSON.parse(storedData) : getDefaultTrackingData();
};

const saveTrackingData = (data: ProductTracking) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
};

export const memorizeSeenProducts = (place: Place, products: Product[]): void => {
  const productIds = products.map(p => p.id);
  const data = getTrackingData();
  const existingIds = data[place] || [];
  data[place] = Array.from(new Set([...existingIds, ...productIds]));
  saveTrackingData(data);
};

export const getNewProductIds = (place: Place, productIds: string[]): string[] => {
  const data = getTrackingData();
  const seenIds = data[place] || [];
  return productIds.filter((id) => !seenIds.includes(id));
};

export const checkIfUserHasUnseenProducts = (place: Place, products: Product[]): boolean => {
  const productIds = products.map(p => p.id);
  return getNewProductIds(place, productIds).length > 0;
};
