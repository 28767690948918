import styles from "components/my-bets/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import React from "react";
import { JoinedContest } from "interfaces/leaderboard/contest.ts";
import { JoinedContestItem } from "components/contests/contest-display-items";
import { useAppSelector } from "state/hooks.ts";
import { getContestsState } from "state/slices/contests-slice.ts";

const MyContests = () => {
  const contestsState = useAppSelector(getContestsState);

  const joinedContests = contestsState.joinedContests;

  const hasContests = joinedContests.length > 0;

  if (!hasContests) {
    return (
      <div className={styles.noBetsTextContainer}>
        <BlockyHeavyText>You haven't joined any contests yet</BlockyHeavyText>
      </div>
    );
  }

  return (
    <>
      {joinedContests.map((joinedContest: JoinedContest) => (
        <JoinedContestItem key={joinedContest.contest_id} joinedContest={joinedContest} />
      ))}
    </>
  );
};

export default MyContests;