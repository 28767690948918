import { useAppSelector } from "state/hooks";
import { useEffect, useState } from "react";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { getGroupState } from "state/slices/group-slice";
import { Bet } from "interfaces/bet.ts";
import { getMyBetsPath, integratedMyBets } from "utils/backend-path-builders.ts";
import { getRequest } from "utils/httpClient.ts";
import { toast } from "react-toastify";
import { useAuth } from "hooks/auth.tsx";


export const useBetsData = (contestEntryId?: string) => {

  const group = useAppSelector(getGroupState);
  const auth = useAuth();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  const [bets, setBets] = useState<Bet[]>();
  const [loadingBets, setLoadingBets] = useState(true);

  useEffect(() => {
    if (!group.id || !auth.signedIn) {
      setLoadingBets(false)
      return;
    }
    const path = isIntegratedApp ? integratedMyBets() : getMyBetsPath(group.id, contestEntryId);
    setLoadingBets(true);
    getRequest(path)
      .then(response => setBets(response))
      .catch(() => toast.error("Something went wrong"))
      .finally(() => setLoadingBets(false));
  }, [group.id, auth.signedIn, contestEntryId]);

  return {
    bets,
    loadingBets
  };
};