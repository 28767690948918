import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "state/hooks";

import { useAuth } from "hooks/auth";
import useWallet from "hooks/useWallet";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import getVerificationStatusIcon from "components/profile/identity-verification-info/getVerificationStatusIcon";
import { getUserState, getVerificationStatus } from "state/slices/user-slice";
import { getGroupState } from "state/slices/group-slice";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { DEFAULT_AVATAR_SRC } from "utils/constants";
import styles from "components/header/user-button.module.css";
import { getRealWalletState } from "state/slices/user-real-wallet-slice.ts";
import PulsingExclamationMarkIcon from "components/common/icons/pulsing-exclamation-mark-icon.tsx";
import { BattlePassProduct } from "interfaces/commerce/battle-pass-product.tsx";
import { ProductMeta } from "interfaces/commerce/product.ts";
import { isBattlePassActive } from "utils/battle-pass.ts";
import { getOrdersState } from "state/slices/user-orders-slice.ts";
import { getProductsState } from "state/slices/products-slice.ts";
import { getBattlePassesState } from "state/slices/battle-passes-slice.ts";
import { checkIfUserHasUnseenProducts, Place } from "utils/seen-product-storage.ts";

const UserButton = () => {
  const auth = useAuth();
  const user = useAppSelector(getUserState);
  const group = useAppSelector(getGroupState);
  const orders = useAppSelector(getOrdersState).orders;
  const products = useAppSelector(getProductsState).products;
  const battlePasses = useAppSelector(getBattlePassesState).battlePasses;

  const location = useLocation();
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const verificationStatus = useAppSelector(getVerificationStatus);
  const { formattedBalance: groupFormattedWallet } = useWallet();
  const realWallet = useAppSelector(getRealWalletState);

  const isContestGroup = group.contest_group;

  const showProfilePage = group.customization.profile_page;
  const showBalance = group.customization.balance;
  const [imgSrc, setImgSrc] = useState(DEFAULT_AVATAR_SRC);

  const IdentityVerificationStatusIcon = getVerificationStatusIcon(verificationStatus);

  const [purchasedOrAvailableForPurchaseBattlePassProducts,
    setPurchasedOrAvailableForPurchaseBattlePassProducts] =
    useState<BattlePassProduct[]>([]);

  useEffect(() => {
    const battlePassProducts = products.reduce((acc, product) => {
      const battlePass = battlePasses
        .find(battlePass => (product.meta as ProductMeta).battle_pass?.id === battlePass.id);

      if (battlePass) {
        acc.push({
          product: product,
          battlePass: battlePass,
          orders: orders.filter((order) => order?.product?.id === product.id),
        } as BattlePassProduct);
      }
      return acc;
    }, [] as BattlePassProduct[]);

    const purchasedOrAvailableForPurchaseBattlePassProducts = battlePassProducts
      .filter(({ battlePass, product }) => isBattlePassActive(battlePass) || product.purchased);

    setPurchasedOrAvailableForPurchaseBattlePassProducts(purchasedOrAvailableForPurchaseBattlePassProducts);
  }, [orders, products, battlePasses]);

  const hasProductsAvailableForPurchase: boolean = purchasedOrAvailableForPurchaseBattlePassProducts
    .filter(({ product }: BattlePassProduct) => !product.purchased).length > 0;

  const hasUnseenProducts = checkIfUserHasUnseenProducts(Place.PROFILE_PAGE, products);

  const notificationIcon = location.pathname !== "/profile" && hasProductsAvailableForPurchase && hasUnseenProducts ?
    <PulsingExclamationMarkIcon /> : null;

  useEffect(() => {
    if (user.avatar) {
      setImgSrc(user.avatar);
    }
  }, [user.avatar]);

  if (auth.signedIn) {
    const balance = isContestGroup ? realWallet.formattedBalance : groupFormattedWallet;

    return (
      <div className={"d-flex flex-column justify-content-center align-items-center"}>

        {showProfilePage && <Link
          to="/profile"
          className={`${styles.avatarLink} ${styles.userButtonContainer}`}
          style={{
            pointerEvents: integratedAppState.isIntegratedApp ? "none" : "auto",
          }}
        >
          <BlockyBoldText className={styles.userName}>
            {user.user_name}
          </BlockyBoldText>

          <div className={styles.avatarContainer}>
            <img alt="Profile Avatar" className={styles.avatar} src={imgSrc} />

            {notificationIcon &&
              React.cloneElement(notificationIcon, {
                width: 16,
                height: 16,
                className: styles.notificationIcon,
              })
            }

            {IdentityVerificationStatusIcon &&
              React.cloneElement(IdentityVerificationStatusIcon, {
                width: 16,
                height: 16,
                className: styles.verificationIcon,
              })}
          </div>
        </Link>
        }

        {showBalance && balance && !group.loading && (
          <BlockyBoldText className={styles.userBalance} style={{
            color: isContestGroup && realWallet.balance === 0 ? "red" : "white"
          }}>
            {balance}
          </BlockyBoldText>
        )}
      </div>
    );
  }

  return <div className={styles.emptySpace} />;
};

export default UserButton;