import React, { useCallback, useState } from "react";
import { Product } from "interfaces/commerce/product.ts";
import { postRequest } from "utils/httpClient.ts";
import { CreateOrderRequest } from "interfaces/commerce/order/create-order-request.ts";
import { Order } from "interfaces/commerce/order/order.ts";
import { useAppDispatch } from "state/hooks.ts";
import { fetchRealWallet } from "state/slices/user-real-wallet-slice.ts";
import { Button } from "react-bootstrap";
import { secondaryColor } from "utils/constants.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";

interface PurchaseModalProps {
  product: Product;
  onSuccessfulPurchase: (order: Order) => void;
  onErrorPurchase: (error: PurchaseError) => void;
}

interface PurchaseError {
  code: "INSUFFICIENT_FUNDS" | "SOMETHING_WENT_WRONG";
}

const PurchaseButton = ({
                          product,
                          onSuccessfulPurchase,
                          onErrorPurchase,
                        }: PurchaseModalProps) => {


  const [purchasing, setPurchasing] = useState(false);

  const dispatch = useAppDispatch();

  const sendPurchaseProductRequest = useCallback(() => {
    return postRequest("/v2/order", {
      body: {
        productId: product.id,
        orderType: "PRODUCT_PURCHASE",
        successPathname: ""
      } as CreateOrderRequest
    });
  }, [product]);

  const getText = () => {
    if (purchasing) {
      return <>Processing ...</>;
    }
    return "Purchase";
  };

  return (
    <Button
      style={{
        background: secondaryColor,
        color: "black",
        border: secondaryColor,
        width: "100%",
      }}
      disabled={purchasing}
      onClick={() => {
        setPurchasing(true);
        sendPurchaseProductRequest()
          .then((order: Order) => {
            onSuccessfulPurchase(order);
          })
          .catch((e) => {
            if (e.response?.data?.technical_error?.includes("insufficient balance")) {
              onErrorPurchase({
                code: "INSUFFICIENT_FUNDS"
              });
            } else {
              onErrorPurchase({
                code: "SOMETHING_WENT_WRONG"
              });
            }
          })
          .finally(() => {
            setPurchasing(false);
            dispatch(fetchRealWallet());
          });
      }}
    >
      <BlockyBoldText>
        {getText()}
      </BlockyBoldText>
    </Button>

  );
};

export default PurchaseButton;
