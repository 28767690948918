import { useAppSelector } from "state/hooks.ts";
import { getGroupState, setGroup } from "state/slices/group-slice.ts";
import { useEffect, useState } from "react";
import { getContestPath, getGroupDetailsPath } from "utils/backend-path-builders.ts";
import { getRequest } from "utils/httpClient.ts";
import { Contest } from "interfaces/leaderboard/contest.ts";
import { clearContestState } from "state/slices/contests-slice.ts";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const useCheckContest = (contestId?: string) => {

  const dispatch = useDispatch();
  const group = useAppSelector(getGroupState);
  const groupId = group.id;

  const [checkingGroup, setCheckingGroup] = useState<boolean>(true);
  const [contest, setContest] = useState<Contest>();

  useEffect(() => {
    if (!contestId) return;

    const path = getContestPath(groupId, contestId);
    getRequest(path, { skipIntegrationApi: true })
      .then((contestResponse: Contest) => {
        setContest(contestResponse);
        if (contestResponse.group_id && contestResponse.group_id !== groupId) {
          const path = getGroupDetailsPath(contestResponse.group_id);

          getRequest(path, { skipIntegrationApi: true })
            .then((data) => {
              dispatch(setGroup(data));
              dispatch(clearContestState());
            });
        }
      })
      .catch(() => toast.error("Contest does not exist."))
      .finally(() => setCheckingGroup(false));
  }, [dispatch, groupId, contestId]);

  return {
    loading: checkingGroup,
    contest,
  };
};

export default useCheckContest;