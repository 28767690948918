import { Nav, Tab } from "react-bootstrap";
import React, { useState } from "react";
import OpenContests from "components/contests/open-contests";
import MyContests from "components/contests/my-contests";
import styles from "./styles.module.css";
import ContestNavItem from "./contest-nav-item.tsx";
import JoinGroupButton from "home/join-group-button";
import { useAuth } from "hooks/auth";
import { useAppSelector } from "state/hooks.ts";
import { getIntegratedAppState } from "state/slices/integrated-app-slice.ts";
import { CONTEST_STATE } from "utils/constants.ts";
import useContestData from "hooks/useContestData.ts";

const OPEN_CONTESTS = "Open Contests";
const MY_CONTESTS = "My Contests";
const TABS = [OPEN_CONTESTS, MY_CONTESTS];

const ContestSelector = () => {

  const { contests, joinedContests } = useContestData();

  const openContests = contests.filter(c => c.state === CONTEST_STATE.OPEN);

  const [selectedTab, setSelectedTab] = useState<typeof TABS[number]>(openContests.length > 0 ? OPEN_CONTESTS : joinedContests.length > 0 ? MY_CONTESTS : OPEN_CONTESTS);
  const auth = useAuth();
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  const handleSelect = (key: string | null) => {
    setSelectedTab(key ?? OPEN_CONTESTS);
  };

  return (
    <Tab.Container
      id="contests-tabs"
      activeKey={selectedTab}
      onSelect={handleSelect}
    >
      <Nav
        variant="pane"
        justify
        className={styles.navBar}
      >
        {TABS.map((tab) => <ContestNavItem key={tab} text={tab} />)}
      </Nav>

      {auth.signedIn && (
        <div className={styles.joinGroupButton}>
          {!isIntegratedApp && <JoinGroupButton />}
        </div>
      )}

      <Tab.Content>
        <Tab.Pane eventKey={OPEN_CONTESTS}>
          <OpenContests />
        </Tab.Pane>
        <Tab.Pane eventKey={MY_CONTESTS}>
          <MyContests />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default ContestSelector;