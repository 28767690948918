import { useAppDispatch } from "state/hooks";
import { Contest, ContestEntry } from "interfaces/leaderboard/contest";
import React, { useState } from "react";
import BlockyLightText from "components/custom-texts/blocky-light-text.tsx";
import DepositFundsModal from "components/commerce/deposit-funds-modal.tsx";
import BlockyMediumText from "components/custom-texts/blocky-medium-text.tsx";
import LongButton from "components/common/button/long-button.tsx";
import { getEnterContestPath } from "utils/backend-path-builders.ts";
import { postRequest } from "utils/httpClient.ts";
import { setUserContestsStale } from "state/slices/contests-slice.ts";
import { PulseLoader } from "react-spinners";
import { ErrorResponseBody } from "interfaces/error-response-body.ts";
import { fetchRealWallet } from "state/slices/user-real-wallet-slice.ts";
import { ErrorCodes } from "interfaces/error-codes.ts";
import { useNavigate } from "react-router-dom";
import { CONTEST_ENTRY_ID_QUERY_PARAM } from "utils/constants.ts";

interface Props {
  contest: Contest;
  setShowModal: (b: boolean) => void;
}

const EnterContestButton = ({ contest, setShowModal }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [sendingEnterRequest, setSendingEnterRequest] = useState(false);

  const [enterContestError, setEnterContestError] = useState<string>("");

  const onClick = async () => {
    setEnterContestError("");

    setSendingEnterRequest(true);
    try {
      const contestEntry: ContestEntry = await postRequest(getEnterContestPath(), {
        body: {
          contest_id: contest.id,
          status: "joined"
        },
      });

      dispatch(setUserContestsStale(true));
      setShowModal(false);

      navigate({
        pathname: window.location.pathname,
        search: `?${CONTEST_ENTRY_ID_QUERY_PARAM}=${contestEntry.id}`,
      }, { replace: true });

    } catch (error: any) {

      const errorResponse: ErrorResponseBody | undefined = error.response?.data;
      if (errorResponse) {
        const error_code = errorResponse.error_code;

        switch (error_code) {
          case ErrorCodes.InsufficientFunds:
            setShowPaymentModal(true);
            break;

          case ErrorCodes.InvalidGeolocation:
            setEnterContestError("Geolocation failed. Please see help section " +
              "for approved jurisdictions. If available, you may join a 'Free' contest instead");
            break;
          default:
            setEnterContestError("Something went wrong");
        }
      } else {
        setEnterContestError("Something went wrong");
      }
    } finally {
      setSendingEnterRequest(false);
      dispatch(fetchRealWallet());
    }
  };

  const enterContestButtonText = "Enter";

  return (
    <>
      {<div>
        <LongButton onClick={onClick} disabled={sendingEnterRequest}>
          {!sendingEnterRequest ? enterContestButtonText :
            <div className={"d-flex justify-content-center"}>
              Running checks <PulseLoader style={{ marginLeft: "0.5rem" }} size={7} />
            </div>}
        </LongButton>

        {
          !enterContestError && <div className={"m-3 text-center text-white"} style={{ fontSize: "10" }}>
            <BlockyLightText>
              By clicking '{enterContestButtonText}', you agree to the contest terms and agree that the Entry Fee
              will be debited from your account
            </BlockyLightText>
          </div>
        }

        {
          enterContestError &&
          <div className={"m-3 text-center rs-text-red"}>
            <BlockyMediumText>
              {enterContestError}
            </BlockyMediumText>
          </div>
        }

        <DepositFundsModal
          show={showPaymentModal}
          onClose={() => setShowPaymentModal(false)}
        />

      </div>
      }
    </>
  );
};

export default EnterContestButton;