import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import styles from "./header-styles.module.scss";
import { toggle as toggleDrawerState } from "state/slices/drawer-slice";
import { useAppDispatch, useAppSelector } from "state/hooks";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import { getGroupState, getGroupStyles } from "state/slices/group-slice";
import GroupPickerModal from "components/group-picker-modal";
import React, { useState } from "react";
import { ChevronDown, ChevronUp, List } from "react-bootstrap-icons";
import { getAvailableGroups } from "state/slices/available-groups-slice";
import { sparketBlack } from "utils/constants";
import UserButton from "components/header/user-button.tsx";

const Header = () => {
  const dispatch = useAppDispatch();
  const group = useAppSelector(getGroupState);
  const groupName = group.name;
  const [showGroupPicker, setShowGroupPicker] = useState(false);
  const { isMobile } = useDeviceDimensions();
  const availableGroups = useAppSelector(getAvailableGroups);
  const hasAvailableGroups = availableGroups.length >= 2;
  const groupSettings = useAppSelector(getGroupStyles);

  const CaretIcon = showGroupPicker ? ChevronUp : ChevronDown;

  return (
    <div style={{ backgroundColor: sparketBlack }} className={styles.container}>
      <div className={styles.logoContainer}>
        <div
          onClick={() => {
            dispatch(toggleDrawerState());
          }}
        >
          {isMobile && (
            <List style={{ color: "white", fontSize: 25, marginTop: 5 }} />
          )}
        </div>
      </div>
      <div onClick={() => setShowGroupPicker(true)}>
        <BlockyBoldText
          className={`${styles.groupNameText} ${styles.groupLogoContainer} ${
            hasAvailableGroups && styles.clickableGroupNameText
          }`}
        >
          {groupSettings.logo_url ? (
            <img
              src={groupSettings.logo_url}
              style={{ maxHeight: 52, width: "85%" }}
              alt={""} />
          ) : (
            groupName
          )}{" "}
          {hasAvailableGroups && <CaretIcon style={{ fontSize: 10 }} />}
        </BlockyBoldText>
      </div>
      <UserButton />
      <GroupPickerModal
        show={hasAvailableGroups && showGroupPicker}
        setShow={setShowGroupPicker}
        availableGroups={availableGroups}
      />
    </div>
  );
};

export default Header;
