import BlockyLightText from "components/custom-texts/blocky-light-text";
import { useEffect, useState } from "react";
import { sparketDarkOrange, sparketDarkRed } from "utils/constants";

interface Props {
  close: Date;
}

const getTimeRemaining = (closeTime: Date) => {
  const total = (closeTime as any) - (new Date() as any);
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  return {
    total,
    days,
    hours,
    minutes,
    seconds,
  };
};

const CountdownClock = ({ close }: Props) => {
  const [timeRemainingData, setTimeRemainingData] = useState(
    getTimeRemaining(close)
  );

  useEffect(() => {
    setInterval(() => setTimeRemainingData(getTimeRemaining(close)), 1000);
  }, [close]);

  const { days, hours, minutes, seconds } = timeRemainingData;

  let displayText = "";
  let displayColor = "";

  if (days > 7) {
    return <></>;
  }

  if (seconds < 0 || minutes < 0 || hours < 0 || days < 0) {
    return <></>
  }

  if (days >= 1) {
    displayText = `${days} days ${hours} hours left`;
    displayColor = sparketDarkOrange;
  } else if (hours >= 1) {
    displayColor = sparketDarkRed;
    displayText = `${hours} hours ${minutes} minutes left`;
  } else if (minutes >= 30) {
    displayColor = sparketDarkRed;
    displayText = `${minutes} minutes left`;
  } else {
    displayColor = sparketDarkRed;
    displayText = `${minutes} minutes ${seconds} seconds left`;
  }
  

  return (
    <BlockyLightText style={{ color: displayColor, fontStyle: "italic" }}>
      {displayText}
    </BlockyLightText>
  );
};

export default CountdownClock;
