import { Pool } from "interfaces/pool.ts";
import React from "react";
import PoolItem from "./index.tsx";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";

interface Props {
  pools: Pool[];
  contestEntryId?: string;
  contestId?: string;
  showCountDownClock: boolean;
  isContestList?: boolean;
}

const PoolList = ({pools, contestEntryId, contestId, showCountDownClock, isContestList}: Props) => {

  return (
    <>
      {pools.length > 0 ? (
        <>
          {pools.map((pool) => {

            const queryParams = new URLSearchParams();
            if (contestEntryId) queryParams.append("contestEntryId", contestEntryId);
            if (contestId && !contestEntryId) queryParams.append("contestId", contestId);

            const link = `/pools/${pool.id}${queryParams.toString() ? `?${queryParams.toString()}` : ""}`;

            return (
              <PoolItem
                key={pool.id}
                styles={styles}
                pool={pool}
                link={link}
                showCountDownClock={showCountDownClock}
                isContestPool={isContestList}
              />
            );
          })}
        </>
      ) : (
        <BlockyHeavyText
          customStyles={{
            display: "flex",
            justifyContent: "center",
            color: "white",
          }}
        >
          No pools are currently open. Please check back later.
        </BlockyHeavyText>
      )}
    </>
  )


}

export default PoolList;