import { useEffect } from "react";
import { XRL_ORDER_ID_INOVIO_QUERY_PARAM } from "payment-provider/inovio/inovio.ts";
import useQueryParams from "utils/useQueryParams.ts";
import { useLocation, useNavigate } from "react-router-dom";

interface Params {
  whenRedirectedFromPaymentPage?: (orderId: string) => void;
  removeInovioParameters?: boolean;
}

export const useInovioSuccessfulPaymentRedirectHandler = ({
                                                            whenRedirectedFromPaymentPage = () => {
                                                            },
                                                            removeInovioParameters = true
                                                          }: Params) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useQueryParams();

  const orderId = queryParams.get(XRL_ORDER_ID_INOVIO_QUERY_PARAM);

  useEffect(() => {
    if (orderId) {
      whenRedirectedFromPaymentPage(orderId);
      if (removeInovioParameters) {
        navigate(location.pathname);
      }
    }
  }, [orderId]);
};
