import React from "react";
import { Form } from "react-bootstrap";
import { UserPaymentMethod } from "interfaces/commerce/user-payment-method.ts";

interface PaymentMethodsSelectorProps {
  paymentMethods: UserPaymentMethod[];
  selectedPaymentMethod?: UserPaymentMethod;
  onSelectPaymentMethod: (method: UserPaymentMethod | undefined) => void;
}

const PaymentMethodsSelector: React.FC<PaymentMethodsSelectorProps> = ({
                                                                         paymentMethods,
                                                                         selectedPaymentMethod,
                                                                         onSelectPaymentMethod,
                                                                       }) => {
  return (
    <div>
      <h5
        className="mb-3 mt-3 text-center"
        style={{ fontSize: "1rem", fontWeight: "bold", color: "#FFFFFF" }}
      >
        Payment methods
      </h5>
      <Form>
        {paymentMethods.map((method) => {
          const checked = selectedPaymentMethod?.id === method.id;
          return (
            <Form.Check
              key={method.id}
              type="radio"
              id={`payment-method-${method.id}`}
              name="paymentMethod"
              value={method.id}
              label={`${method.card_brand} ending in ${method.last4} (Exp: ${method.expiry_month}/${method.expiry_year})`}
              className="text-white mb-2"
              checked={checked}
              onChange={() => onSelectPaymentMethod(method)}
            />
          );
        })}

        <Form.Check
          type="radio"
          id="new-payment-method"
          name="paymentMethod"
          value="new"
          label="Use a new payment card"
          className="text-white mb-2"
          checked={!selectedPaymentMethod}
          onChange={() => onSelectPaymentMethod(undefined)}
        />
      </Form>
    </div>
  );
};

export default PaymentMethodsSelector;
