import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";
import { Wallet } from "interfaces/wallet.ts";
import { getWalletPath } from "utils/backend-path-builders.ts";
import Dinero from "dinero.js";

export interface RealWalletState {
  walletId: string;
  balance?: number;
  formattedBalance?: string;
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: RealWalletState = {
  walletId: "",
  balance: undefined,
  formattedBalance: undefined,
  fetchStatusState: "loading",
  error: null,
};

const NIL_GROUP_ID = "00000000-0000-0000-0000-000000000000";

export const fetchRealWallet = createAsyncThunk<
  Wallet,
  void, // No payload
  { rejectValue: string }
>("realWallet/fetch", async (_, { getState, rejectWithValue }) => {
  try {

    return await getRequest(getWalletPath(NIL_GROUP_ID));
  } catch (err) {
    toast.error("Error fetching usd balance.");
    console.error(err);
    return rejectWithValue("Failed to fetch usd balance methods");
  }
});

const slice = createSlice({
  name: "realWallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRealWallet.pending, (state) => {
      state.fetchStatusState = "loading";
      state.error = null;
    });
    builder.addCase(fetchRealWallet.fulfilled, (state, action) => {
      state.fetchStatusState = "succeeded";

      const balance = action.payload.current_balance;
      state.balance = balance;
      state.walletId = action.payload.wallet_id

      const balanceInCents = Math.round(balance * 100);

      // Check if it's an integer
      if (Number.isInteger(balance)) {
        // No decimals
        state.formattedBalance = Dinero({ amount: balanceInCents }).toFormat("$0");
      } else {
        // Two decimals
        state.formattedBalance = Dinero({ amount: balanceInCents }).toFormat("$0.00");
      }

      state.error = null;
    });
    builder.addCase(fetchRealWallet.rejected, (state, action) => {
      state.fetchStatusState = "failed";
      state.balance = 0;
      state.error = action.payload ?? "Something went wrong.";
    });
  },
});

export const getRealWalletState = (state: RootState): RealWalletState => state.realWallet;

export default slice.reducer;
