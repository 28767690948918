import React, { ReactNode } from "react";
import { Button } from "react-bootstrap";
import { secondaryColor } from "utils/constants.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";

const buttonStyles = {
  background: secondaryColor,
  color: "black",
  border: secondaryColor,
  width: "100%",
};

interface Props {
  onClick: () => void;
  children: ReactNode;
  disabled?: boolean;
}

const LongButton = ({ onClick, children, disabled = false }: Props) => {
  return (
    <Button
      style={buttonStyles}
      onClick={onClick}
      disabled={disabled}
    >
      <BlockyBoldText>
        {children}
      </BlockyBoldText>
    </Button>
  );
};

export default LongButton;
