import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";
import { Product } from "interfaces/commerce/product.ts";

export interface ProductsState {
  products: Product[];
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: ProductsState = {
  products: [],
  fetchStatusState: "loading",
  error: null,
};

export const fetchProducts = createAsyncThunk<
  Product[],
  void,
  { rejectValue: string }
>("products/fetch", async (_, { getState, rejectWithValue }) => {
  try {

    return await getRequest("/v2/product") || [];
  } catch (err) {
    console.error(err);
    return rejectWithValue("Failed to fetch products");
  }
});

const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    clearOrdersState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.fetchStatusState = "loading";
      state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.fetchStatusState = "succeeded";
      state.products = action.payload;
      state.error = null;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.fetchStatusState = "failed";
      state.error = action.payload ?? "Something went wrong.";
    });
  },
});

export const getProductsState: (state: RootState) => ProductsState = (state: RootState) => state.products;

export default slice.reducer;
