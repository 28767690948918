import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getRequest } from "utils/httpClient";
import { UserPaymentMethod } from "interfaces/commerce/user-payment-method";
import { RootState } from "../store";

export interface PaymentMethodsState {
  items: UserPaymentMethod[];
  fetchPaymentMethodsStatus: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: PaymentMethodsState = {
  items: [],
  fetchPaymentMethodsStatus: "idle",
  error: null,
};

export const fetchPaymentMethods = createAsyncThunk<
  UserPaymentMethod[],
  void, // No payload
  { rejectValue: string }
>("paymentMethods/fetch", async (_, { getState, rejectWithValue }) => {
  const state = getState() as RootState;
  const userId = state.user.id;

  if (!userId) {
    return rejectWithValue("No user ID found in store.");
  }

  try {
    return await getRequest(`/v2/users/${userId}/payment-methods`) || [];
  } catch (err) {
    toast.error("Error fetching payment methods.");
    console.error(err);
    return rejectWithValue("Failed to fetch payment methods");
  }
});

const slice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentMethods.pending, (state) => {
      state.fetchPaymentMethodsStatus = "loading";
      state.error = null;
    });
    builder.addCase(fetchPaymentMethods.fulfilled, (state, action) => {
      state.fetchPaymentMethodsStatus = "succeeded";
      state.items = action.payload;
      state.error = null;
    });
    builder.addCase(fetchPaymentMethods.rejected, (state, action) => {
      state.fetchPaymentMethodsStatus = "failed";
      state.error = action.payload ?? "Something went wrong.";
      state.items = [];
    });
  },
});

export const getPaymentMethodsState: (state: RootState) => PaymentMethodsState = (state: RootState) => state.paymentMethods;

export default slice.reducer;
