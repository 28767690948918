import { useAuth } from "hooks/auth";
import { Link, Navigate } from "react-router-dom";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { secondaryColor, sparketBlack, sparketLightGray, SUCCEEDED } from "utils/constants";
import Header from "components/header";
import SignoutButton from "./signoutButton";
import ProfileInfo from "./profileInfo";
import GroupDetails from "./group-details";
import { Button, Spinner } from "react-bootstrap";
import Headroom from "react-headroom";
import { Mixpanel } from "hooks/mixPanel";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { getUserState } from "state/slices/user-slice";
import NotificationConfiguration from "components/profile/notification-configuration";
import SparketScore from "./sparket-score/sparketScore";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";
import getVerificationStatusIcon from "components/profile/identity-verification-info/getVerificationStatusIcon";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import AvatarAndBadgesAndOrders from "components/avatar/avatar-and-badges-and-orders.tsx";
import React, { useState } from "react";
import MyOrdersButton from "components/profile/orders/my-orders-button.tsx";
import DepositFundsModal from "components/commerce/deposit-funds-modal.tsx";
import BalanceDisplay from "components/common/real-money-wallet/balance-display.tsx";
import { useInovioSuccessfulPaymentRedirectHandler } from "hooks/useInovioSuccessfulPaymentRedirectHandler.ts";

interface ProfileSummaryProps {
  email: string;
}

// Top half of profile
const ProfileSummary = ({ email }: ProfileSummaryProps) => {

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  useInovioSuccessfulPaymentRedirectHandler({
    removeInovioParameters: true
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BlockyBoldText style={{ color: secondaryColor, fontSize: 30 }}>
        Profile
      </BlockyBoldText>

      <AvatarAndBadgesAndOrders />

      <div className="d-flex align-items-center justify-content-center m-2 position-relative">
        <div
          style={{
            color: "white",
            background: "transparent",
            cursor: "pointer",
            border: "none",
            fontSize: 18,
            fontStyle: "italic",
            textDecoration: "underline",
          }}
          onClick={() => setShowPaymentModal(true)}
        >
          Deposit Funds
        </div>
      </div>

      <DepositFundsModal
        show={showPaymentModal}
        onClose={() => setShowPaymentModal(false)}
      />

      <MyOrdersButton />

      <SparketScore />

      <br />

      <BlockyBoldText style={{ color: "white" }}>Signed in as {email}</BlockyBoldText>
    </div>
  );
};
// Bottom half of profile
const ProfileDetails = () => {
  const user = useAppSelector(getUserState);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <BlockyBoldText>Personal Info</BlockyBoldText>
      <ProfileInfo username={user.user_name} />
      <BlockyBoldText>Email Notification Settings</BlockyBoldText>
      <NotificationConfiguration />
    </div>
  );
};

interface IdentityVerificationButtonProps {
  verificationStatus: IDENTITY_VERIFICATION_STATUS;
}

const IdentityVerificationButton = ({ verificationStatus }: IdentityVerificationButtonProps) => {

  const auth = useAuth();

  const getButtonText = () => {
    switch (verificationStatus) {
      case IDENTITY_VERIFICATION_STATUS.NOT_STARTED:
        return "Verify Identity";
      case IDENTITY_VERIFICATION_STATUS.APPROVED:
        return "Identity Verified";
      case IDENTITY_VERIFICATION_STATUS.REJECTED:
      case IDENTITY_VERIFICATION_STATUS.DUPLICATED:
      case IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE:
        return "Update ID Verification";
      default:
        return "Verify Identity";
    }
  };

  const Icon: JSX.Element | null = getVerificationStatusIcon(verificationStatus);
  const text = getButtonText();

  const verified = verificationStatus === IDENTITY_VERIFICATION_STATUS.APPROVED;

  const buttonText = (
    <BlockyBoldText>
      <div className="d-flex gap-1 justify-content-center align-items-center">
        {Icon &&
          React.cloneElement(Icon, {
            width: 20,
            height: 20
          })}
        {text}
      </div>
    </BlockyBoldText>
  );

  return <Button
    disabled={verified}
    style={{
      opacity: "initial",
      backgroundColor: sparketLightGray,
      color: sparketBlack,
      border: `1px solid ${secondaryColor}`,
      borderRadius: "unset",
      height: 40,
      minWidth: 150,
    }}
  >
    {verified ?
      buttonText :
      <Link
        to="/edit-profile"
        style={{
          textDecoration: "none",
          color: sparketBlack
        }}
        onClick={(e) =>
          Mixpanel.track("Clicked Edit Profile", { isSignedIn: auth.signedIn, })}
      >
        {buttonText}
      </Link>
    }
  </Button>;
};

const getIdentityVerificationHelperTest = (verificationStatus: IDENTITY_VERIFICATION_STATUS) => {

  let message = <></>;

  if ([IDENTITY_VERIFICATION_STATUS.REJECTED,
    IDENTITY_VERIFICATION_STATUS.DUPLICATED,
    IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE].includes(verificationStatus)) {
    message = <BlockyBoldText className={"text-center"}>
      Your ID verification failed.
      Please check your ID and update your information, or contact
      support@betsparket.com.
    </BlockyBoldText>;
  } else if (IDENTITY_VERIFICATION_STATUS.APPROVED === verificationStatus) {
    message = <BlockyLightText className={"text-center p-2"}>
      If you need to update your verified or pending submission, please contact{" "}
      <a href="mailto:support@betsparket.com">support@betsparket.com</a>.
    </BlockyLightText>;
  }

  return <div className={"d-flex justify-content-center p-1"}>
    {message}
  </div>;
};

const Profile = () => {
  const auth = useAuth();

  const group = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);

  if (auth.pending) {
    return <div>loading...</div>;
  }

  return (
    <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <Headroom>
        <Header />
      </Headroom>
      {auth.signedIn ? (
        <>
          <div
            style={{
              backgroundColor: sparketBlack,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <ProfileSummary email={auth.getEmail()} />
          </div>
          {user.fetchUserState === SUCCEEDED ? <div>
              <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
                <IdentityVerificationButton verificationStatus={user.validation_status} />
                <SignoutButton />
              </div>
              {getIdentityVerificationHelperTest(user.validation_status)}
              <div className={"mt-5"}>
                <ProfileDetails />
              </div>
            </div> :
            <div className={"d-flex justify-content-center align-content-center align-items-center flex-grow-1"}>
              <Spinner animation="border" variant="warning" />
            </div>
          }

          {group.status === UserGroupMembershipStatus.APPROVED && group.settings.external_id_label && (
            <GroupDetails />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </div>
  );
};

export default Profile;
