import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import styles from "./styles.module.css";
import { secondaryColor, sparketBlack } from "utils/constants.ts";
import {
  PrizeDisplay,
  EntryFeeDisplay,
  MaxEntriesDisplay,
  ContestStatusDisplay
} from "./shared/contest-displays";
import TimeDisplay from "./shared/time-display.tsx";
import { InfoCircleFill } from "react-bootstrap-icons";
import StatusDisplay from "./entry-status"
import CountdownClock from "components/pool-item/countdown-clock.tsx";

interface Props {
  contest: any;
  setShow?: any;
  contestEntryId?: string;
  showStatus?: boolean
}
const ContestItem = ({ contest, setShow, contestEntryId, showStatus }: Props) => {

  const includeInfoCircle = !!contestEntryId;

  const {
    max_entries,
    total_entries,
    entry_fee,
    name,
    headline,
  } = contest;

  return (
    <div style={{
      borderRadius: 10,
      border: "1px solid var(--secondary-color)",
      position: "relative",
    }}>
      {includeInfoCircle && (
        <div
          style={{
            position: "absolute",
            top: 5,
            right: 10,
            cursor: "pointer",
            color: secondaryColor,
          }}
          onClick={() => setShow && setShow(true)}
        >
          <InfoCircleFill size={20} />
        </div>
      )}
      <div
        style={{
          backgroundColor: sparketBlack,
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        {/* title/headline */}
        <div>
          <BlockyHeavyText
            style={{
              width: "100%",
              fontSize: 12,
            }}
          >
            <div
              style={{
                paddingLeft: 25,
                paddingRight: 25,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  justifyContent: "center",
                  display: "flex",
                  color: secondaryColor,
                }}
              >
                {name}
              </div>
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  fontSize: 11,
                }}
                className={styles.entryText}
              >
                {headline}
              </div>
            </div>
          </BlockyHeavyText>
        </div>

        <div className={styles.contestDetailsWrapper}>
          {/* left panel */}
          <MaxEntriesDisplay maxEntries={max_entries} totalEntries={total_entries} />

          {/* divider */}
          <div className={styles.divider} />

          {/* middle pannel */}
          <EntryFeeDisplay entryFee={entry_fee} />

          {/* divider */}
          <div className={styles.divider} />

          {/* right panel */}
          <PrizeDisplay contest={contest} />
        </div>

        {/* bottom bar */}
        <div style={{ marginTop: 10 }}>
          <div className={styles.bottomBar}>
            <div className={styles.bottomColumn}>
              <TimeDisplay endAt={contest.end_at} />
              {!!showStatus && <ContestStatusDisplay status={contest.state} />}
            </div>
            <div className={styles.bottomColumn} style={{alignItems: "flex-end"}}>
              {contestEntryId && <StatusDisplay contestEntryId={contestEntryId} />}
              <div style={{ fontSize: 12, alignItems: "center" }}>
                <CountdownClock close={new Date(contest.end_at)} />
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ContestItem;
